import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { UserService, AuthenticationService } from '../../_services';
import { LoginUser } from '../../_models';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    auditType: any;
    auditTypeValues: any;
    auditTableData: any;
    reports = 'none';
    reportsExcel = 'none';
    auditTyperadio = 'block';
    dataValues = 'none';
    assignPRBtn: boolean = true;
    managePRs: boolean = true;
    reportsBtn: boolean = true;
    isReport = false;
    currentDate = '';

    ID: any;
    auditCard = 1;
    claimCard = 0;
    dataCard = 0;
    reportsCArd = 0;
    reportsCArdExcel = 0;
    selectedAudit: any;
    selectedAuditvalue: any;
    selectedDataValue: null;
    selectedReportValue: any;

    dataBtnhide: boolean = true;

    databtn = 'none';
    reportsbtn = 'none';
    managePRbtn = 'block';
    showAssignPRbtn = 'block';
    dataDropDown: any;
    // currentUser: any;

    loading = false;
    currentUser: LoginUser;
    userFromApi: LoginUser;
    loggedInUser: any;
    reportLookup = [];

    username: any;
    lang: string = 'es';
    pageID: any;
    constructor(private userService: UserService, private authenticationService: AuthenticationService, private render: Renderer2, private apiservice: apiService, private router: Router, public activatedRoute: ActivatedRoute, public translate: TranslateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
        
        this.currentUser = this.authenticationService.currentUserValue;
        

        translate.addLangs(['en', 'es']);
        //this.translate.setDefaultLang('es');
        //localStorage.setItem('lang', 'es')
        let lang = localStorage.getItem('lang');
        if (lang === null || lang === 'null') {
            this.selectedLanguage = 'Spanish';
            translate.setDefaultLang('es');
            localStorage.setItem('lang', 'es');
            this.changeLang(localStorage.getItem('lang'));
        } else {
            // const browserLang = translate.getBrowserLang();
            // lang = browserLang.match(/en|es/) ? browserLang : 'en';
            this.changeLang(lang);
        }
    }

    
    ngOnInit() {
        
        const langcheck = localStorage.getItem('lang');
        if (langcheck == undefined) {
            this.translate.setDefaultLang('es');
            this.changeLang(langcheck);
        } else {
            this.translate.setDefaultLang(langcheck);
            this.changeLang(langcheck);
        }
    
        this.currentDate = moment().format('DD/MM/YYYY');
    
        
        this.username = 'Guest'; 
    
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        console.log("user", prompt);
        
        if (prompt && prompt.username) {
            this.username = prompt.username; 
        }
    
        console.log("your username", this.username);
        
        this.onPageLoad();
        this.ID = this.activatedRoute.snapshot.params['ID'];
        this.currentUser = this.authenticationService.currentUserValue;
        
        
        if (this.currentUser) {
            this.showBtnUser(this.currentUser.role);
        }
    
        this.loadAuditTypeValues(this.ID);
        this.dataDropDownValue(this.ID);
        this.getAllDropdownValues(this.ID, this.pageID);
    }
    

    selectedLanguage: any;
    dropDownLanguage: any;
    valueLang: any;

    languageDropdown(CLCode) {
        let lang = localStorage.getItem('lang');
        if (CLCode == 'CL') {
            // this.translate.addLangs(['en', 'es']);
            if (lang === null) {
                this.translate.setDefaultLang('es');
                this.selectedLanguage = 'Spanish';
                this.dropDownLanguage = 'English';
                this.valueLang = 'es';
            } else {
                if (lang == 'es') {
                    this.changeLang(lang);
                    this.selectedLanguage = 'Spanish';
                    this.dropDownLanguage = 'English';
                    this.valueLang = 'es';
                    this.translate.setDefaultLang('es');
                }
                if (lang == 'en') {
                    this.changeLang(lang);
                    this.dropDownLanguage = 'Spanish';
                    this.selectedLanguage = 'English';
                    this.valueLang = 'en';
                    this.translate.setDefaultLang('en');
                }
            }
        } else {
            this.changeLang(lang);
            this.dropDownLanguage = 'Spanish';
            this.selectedLanguage = 'English';
            this.valueLang = 'en';
            this.translate.setDefaultLang('en');
        }
    }

    getAllDropdownValues(CLCode, pageID) {
        this.reportLookup = [];


        this.apiservice.getClaimsDropdowns(CLCode, pageID).subscribe((response) => {
            for (var i = 0; i < response.length; i++) {
                if (response[i].Lookup_Code == 'REPORT_SCREEN') {
                    this.reportLookup.push(response[i]);
                    localStorage.setItem('report_lookup', JSON.stringify(this.reportLookup));
                }
            }
        });
    }

    onPageLoad() {
        this.loading = true;
        this.userService
            .getById(this.currentUser.id)
            .pipe(first())
            .subscribe((user) => {
                this.loading = false;
                this.userFromApi = user;
                // this.loggedInUser = this.currentUser.username
                this.loggedInUser = this.username;
                console.log("your username",this.username);
            });
    }

    showBtnUser(user) {
        if (user == 'AUDITOR') {
            this.showAssignPRbtn = 'none';
        } else {
            this.showAssignPRbtn = 'block';
        }
    }

    public async logout(): Promise<void> {
        if (confirm('Are you sure want to Logout?')) {
            this._oktaAuth.tokenManager.clear();
            await this._oktaAuth.signOut().then((_) => {
                this.router.navigate(['/login']);
            });

            localStorage.removeItem('userinfo');
            localStorage.removeItem('report_lookup');
            localStorage.removeItem('okta-shared-transaction-storage');
            localStorage.removeItem('okta-token-storage');
            localStorage.removeItem('okta-original-uri-storage');
            localStorage.removeItem('currentUser');
            localStorage.removeItem('okta-cache-storage');
            localStorage.removeItem('country');
            // this.router.navigate(['/login']);
        }
    }

   

   
    

    

    showData() {
        this.auditCard = 0;
        this.claimCard = 0;
        this.dataCard = 1;
        this.reportsCArd = 0;
        this.reportsCArdExcel = 0;
        this.reports = 'none';
        this.reportsExcel = 'none';
        this.auditTyperadio = 'none';
        this.dataValues = 'block';
        this.databtn = 'block';
        this.reportsbtn = 'none';
        this.managePRbtn = 'none';
        this.showAssignPRbtn = 'none';

        //  reportsBtn:boolean=true;
    }

    showAuditType() {
        this.auditCard = 1;
        this.claimCard = 0;
        this.dataCard = 0;
        this.reportsCArd = 0;
        this.reportsCArdExcel = 0;
        this.databtn = 'none';
        this.reportsbtn = 'none';
        this.managePRbtn = 'block';
        // this.showAssignPRbtn = "block";
        this.dataValues = 'none';
        this.auditTyperadio = 'block';
        this.reports = 'none';
        this.reportsExcel = 'none';

        if (this.currentUser.role == 'AUDITOR') {
            this.showAssignPRbtn = 'none';
        } else {
            this.showAssignPRbtn = 'block';
        }
    }


    showReports() {
        this.auditCard = 0;
        this.claimCard = 0;
        this.dataCard = 0;
        this.reportsCArd = 1;
        this.reportsCArdExcel = 0;
        this.reports = 'block';
        this.reportsExcel = 'none';
        this.auditTyperadio = 'none';
        this.dataValues = 'none';
        this.databtn = 'none';
        this.reportsbtn = 'block';
        this.managePRbtn = 'none';
        this.showAssignPRbtn = 'none';
    }

    showReportsExcel() {
        this.auditCard = 0;
        this.claimCard = 0;
        this.dataCard = 0;
        this.reportsCArd = 0;
        this.reportsCArdExcel = 1;
        this.reports = 'none';
        this.reportsExcel = 'block';
        this.auditTyperadio = 'none';
        this.dataValues = 'none';
        this.databtn = 'none';
        this.reportsbtn = 'block';
        this.managePRbtn = 'none';
        this.showAssignPRbtn = 'none';
    }



    showClaims() {
        if (this.currentUser.role == 'AUDITOR' || this.currentUser.role == 'systemUser' || this.currentUser.role == 'PPAManager' || this.currentUser.role == 'admin') {
            this.auditCard = 0;
            this.claimCard = 1;
            this.dataCard = 0;
            this.reportsCArd = 0;
            this.reportsCArdExcel = 0;
            this.databtn = 'none';
            this.reportsbtn = 'none';
            this.reports = 'none';
            this.reportsExcel = 'none';


            this.router.navigate(['/pages/create-claim/' + this.ID]);
        } else {
            alert('You dont have access to this page');
        }

        //  this.router.navigate(['/pages/create-claim']);
    }

    assignPr(item) {
        this.router.navigate(['/pages/assign/' + item.country_code + '/' + item.Lookup_Values]);
    }
    managePR(item) {
        this.router.navigate(['/pages/managePR/' + item.country_code + '/' + item.Lookup_Values]);
    }

    dataPage(item) {
        // this.dataBtnhide = false
        this.router.navigate(['/pages/data/' + this.ID + '/' + item]);
    }

    reportsPage(item) {
        if (this.ID == "CL") {
            if (item == 'DMM Dasboard CL') {
                window.open('https://app.powerbi.com/groups/be764ccc-02e4-4718-a93b-940bf2fdf97a/reports/a378b237-a9be-4dff-a49a-f5e21be39155/ReportSection92b21a2f2757d1102e75', '_blank')

            }
            else if (item == 'Pending Claims') {
                window.open('https://app.powerbi.com/groups/be764ccc-02e4-4718-a93b-940bf2fdf97a/reports/a378b237-a9be-4dff-a49a-f5e21be39155/ReportSection92b21a2f2757d1102e75', '_blank')

            }
            else if (item == 'Recovery_KPI_Expenses Report') {
                window.open('https://app.powerbi.com/groups/be764ccc-02e4-4718-a93b-940bf2fdf97a/reports/d286ec74-002f-4e75-b2ae-a9b73d74fe82/ReportSectionc0978235073b1a5091dc', '_blank')
            }
        }
        else {
            if (item == 'DMM Dashboard MX') {
                window.open('https://app.powerbi.com/groups/be764ccc-02e4-4718-a93b-940bf2fdf97a/reports/8e835e38-68f0-4a5d-b3a0-9b6756762462/ReportSection92b21a2f2757d1102e75', '_blank')

            }
            else if (item == 'Pending Claims') {
                window.open('https://app.powerbi.com/groups/be764ccc-02e4-4718-a93b-940bf2fdf97a/reports/02991ca5-ee35-4e82-88b0-a73330716914/ReportSectionc0978235073b1a5091dc', '_blank')

            }
            else if (item == 'Recovery_KPI_Expenses Report') {
                window.open('https://app.powerbi.com/groups/be764ccc-02e4-4718-a93b-940bf2fdf97a/reports/d286ec74-002f-4e75-b2ae-a9b73d74fe82/ReportSectionc0978235073b1a5091dc', '_blank')
            }
        }
    }

    auditTypeRadio() {
        this.assignPRBtn = false;
        this.managePRs = false;
    }
    dataType() {
        this.dataBtnhide = false;
    }
    reportsType() {

        this.reportsBtn = false;
    }


    loadAuditTypeValues(CLCode) {
        this.apiservice.getAuditType(CLCode).subscribe((response) => {
            this.auditTypeValues = response;
        });
    }
    dataDropDownValue(CLCode) {
        this.apiservice.getDataDropdown(CLCode).subscribe((response) => {
            this.dataDropDown = response;
        });
    }

    changeLang(lang: string) {
        localStorage.setItem('lang', lang);

        this.translate.use(lang);
        if (lang == 'en') {
            this.selectedLanguage = 'English';
            this.dropDownLanguage = 'Spanish';
        } else if (lang == 'es') {
            this.selectedLanguage = 'Spanish';
            this.dropDownLanguage = 'English';
        }
    }
}
