import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { EditDetails } from '../_models/editDetails';
import { CreateClaimModal } from '../_models/createClaim';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class apiService {
    
    getmxMarkdowncalc(finalPayload: any[]) {
        throw new Error('Method not implemented.');
    }
    domain = environment.ppa;
    countryCode = this.domain + '/getDistinctCountryCd';
    auditTable = this.domain + '/getAuditData';
    loginCLCode = this.domain + '/getByIdAuditType';
    auditTableData = this.domain + '/getAuditDataByAuditType';
    editDataMPR = this.domain + '/EditDataRow';
    allowTypes = this.domain + '/getDistinctAllowTypes';
    addNewDataRow = this.domain + '/AddDataRow';
    addNewDataRowData = this.domain + '/AddRowDataModule';
    auditorList = this.domain + '/getAuditorDropdown';
    changeStatus = this.domain + '/getChangePRDropdown';
    assignAuditor = this.domain + '/AssignAuditor';
    changePRAuditorStatus = this.domain + '/ChangePR';
    getClaimsData = this.domain + '/getAuditDataByRecordID';
    claimsDropDowns = this.domain + '/getClaimDropdown';
    addClaimsData = this.domain + '/AddClaim';
    fileUploadAPI = this.domain + '/FileUpload';
    getDepartMentDropDown = this.domain + '/getDeptNoDropdown';
    bulkUpload = this.domain + '/BulkUpdateAudit';
    dataDropDown = this.domain + '/getDataScreenDropdown';
    // paybackclaim = this.domain + '/AddPaybackClaim';
    paybackclaimsData = this.domain + '/getClaimDataById';
    getAllClaimData = this.domain + '/getClaimData';
    dataModuleTable = this.domain + '/getData';
    claimTemplateUpload = this.domain + '/ClaimTemplateUpload';
    claimVolumeTemplateUpload = this.domain + '/MxClaimTemplateUploadVolume';
    getFileDataByClaimId = this.domain + '/getFileDetails';
    claimPackageUpdate = this.domain + '/ClaimPackageUpdate';
    BulkUpdateData = this.domain + '/BulkUpdateDataScreen';
    checkHistoryAPI = this.domain + '/getCheckHistory';
    getAuditYearData = this.domain + '/getAuditYearDropdown';
    getFilePreview = this.domain + '/FilePreview';
    getAllAuditDataByYear = this.domain + '/getAllAuditData';
    getPRAuditYearDropdown = this.domain + '/getPRAuditYearDropdown';
    getPontOfSaleUrl = this.domain + '/getDataPointOfSales';
    getCalculatedFieldsNewStore = this.domain + '/CalculatedFieldsNewStore';
    getCalculatedFieldsVolume = this.domain + '/CalculatedFieldsVolumes';
    dataFilters = this.domain + '/getDataFilters';
    exportAdvanceFilter = this.domain + '/getDataFiltersDownload';
    claimNoGeneration = this.domain + '/getClaimNo';
    exportNormal = this.domain + '/getDataDownload';
    oldClaimApI = this.domain + '/getClaimDataById';
    gethistory = this.domain + '/getCheckHistory/';
    getAuditDataByClaimIDPR = this.domain + '/getAuditDataByClaimID/';
    filterBulkUpdateCL = this.domain + '/BulkUpdateWithFilterDataScreenCL';
    getChartData = this.domain + '/getMarkdownPOSRetailCorrect';

    // API for Mexico Scan Allowance
    getmxscaudityr = this.domain + '/getAuditYearMX';
    getmxscgrps = this.domain + '/getGroupsScanAllowanceMX';
    getmxscdep = this.domain + '/getDepScanAllowanceMX';
    getmxscauditorlist = this.domain + '/getAuditorScanAllowanceMX';
    getmxscstatuslist = this.domain + '/getStatusScanAllowanceMX';
    getmxscdata = this.domain + '/getAuditDataMX';
    getlookupmx = this.domain + '/getMXLookupValues';
    multipleclaimsMX = this.domain + '/getMultipleAuditDataByRecordIDMX';
    multipleclaimsCL = this.domain + '/getMultipleAuditDataByRecordIDCL';
    //addrecmxsc = this.domain + '/AddRowMXScanAllowance';
    addrecmxsc = this.domain + '/AddRowMX';
    saveDataAmount = this.domain + '/EditRowDataModule';
    getVendorDetails = this.domain + '/getMXVendorDetails';

    gethistmxsc = this.domain + '/getHistoryQRMX/';
    getmxsccalc = this.domain + '/getScanAllowanceMXCalculation';
    getmxVolumecalc = this.domain + '/getVolumeAllowanceMXCalculation';
    getmxfrcalc = this.domain + '/getFillRateMXCalculation';
    getmxMSIcalc = this.domain + '/getMSIMXCalculation';
    getmxscitmnbr = this.domain + '/getMXItemNbr/';
    getmxmarkcalc = this.domain + '/getMarkdownMXCalculation';
    getmxscvendorlist = this.domain + '/getMXVendor/';
    germxformats = this.domain + '/getMXFormatDetailsQR';
    getDMScanRebatesMX = this.domain + '/getDataModuleScanRebatesMX/';
    getDMWMItemMasterMX = this.domain + '/getDataModuleWMItemMasterMX/';
    getDMItemMasterSamsMX = this.domain + '/getDataModuleItemMasterSamsMX/';
    getDMWMPOSDataMX = this.domain + '/getDataModuleWMPOSDataMX/';
    getDMSAMSPOSDataMX = this.domain + '/getDataModuleSAMSPOSDataMX/';
    dataMxModuleTable = this.domain + '/getDataMX';
    dataFiltersMs = this.domain + '/getDataFiltersMX';
    auditFilterMx = this.domain + '/AuditFiltersMX';
    auditFilterCl = this.domain + '/AuditFiltersCL';
    pivotTableAPICL = this.domain + '/getPivotDataCLDWH';
    pivotTableAPIMX = this.domain + '/getPivotDataMXDWH';
    columnSchemasAPICL = this.domain + '/getColumnDataTypesCL';
    columnSchemasAPIMX = this.domain + '/getColumnDataTypesMX';
    getClaimsDataMX = this.domain + '/getAuditDataByRecordIDMX';

    claimTemplateUploadMX = this.domain + '/MxClaimTemplateUpload';
    claimVolumeTemplateUploadMX = this.domain + '/MxClaimTemplateUploadVolume';
    claimPackageUpdateMX = this.domain + '/MxClaimPackageUpdate';
    addClaimsDataMX = this.domain + '/AddClaimMX';
    BulkUpdateDataMx = this.domain + '/BulkUpdateDataScreenMX';
    exportAdvanceFilterMX = this.domain + '/getDataFiltersDownloadMX';
    claimNoGenerationMX = this.domain + '/getClaimNoMX';
    exportNormalMX = this.domain + '/getDataDownloadMX';
    exportAuditMX = this.domain + '/AuditFiltersDownloadMX';
    getPontOfSaleUrlMX = this.domain + '/getDataMX';
    getAuditDataByClaimIDMXPR = this.domain + '/getAuditDataByClaimIDMX';
    filterBulkUpdateMX = this.domain + '/BulkUpdateWithFilterDataScreenMX';
    vendordetailsData = this.domain + '/getMXVendorDetails';
    formatforDWHtables = this.domain + '/getMXFormatDetailsDWH';
    volumeAllowanceDetail = this.domain + '/getVolumeAllowanceDetailMXCalculation';
    volumeItemDetail = this.domain + '/getMXItemDetails';

    constructor(private http: HttpClient, private title: Title, private translateService: TranslateService) { }
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    // Handle API errors
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            
            console.error('An error occurred:', error.error.message);
        } else {
            
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        
        return throwError('Something bad happened; please try again later.');
    }

    AuditID: any;
    setSession(key: string, value: any): void {
        //sessionStorage.setItem(key, JSON.stringify(value));
        localStorage.setItem(key, JSON.stringify(value));
    }
    getSession(key: string): any {
        if (typeof window !== 'undefined') {
            //let retrievedObject = sessionStorage.getItem(key) as string;
            let retrievedObject = localStorage.getItem(key) as string;
            return retrievedObject;
        }
    }

    getList(): Observable<any> {
        return this.http.get<any>(this.auditTable).pipe(retry(2), catchError(this.handleError));
    }

    getAuditType(code): Observable<User> {
        return this.http.get<User>(this.loginCLCode + '/' + code).pipe(retry(2), catchError(this.handleError));
    }

    getAllowTypes() {
        return this.http.get<User>(this.allowTypes).pipe(retry(2), catchError(this.handleError));
    }

    getAuditTableData(auditType, pageNumber, year) {
        return this.http

            .get<User>(this.auditTableData + '/' + auditType + '/' + pageNumber + '/' + year)

            .pipe(
                retry(2),

                catchError(this.handleError)
            );
    }

    getAuditTableDataByYear(auditType, year, country, page?) {
        let data;
        if (country === 'CL') {
            data = this.getAllAuditDataByYear + '/' + auditType + '/' + year;
        } else if (country === 'MX') {
            data = this.getmxscdata + '/' + auditType + '/' + year + '/' + page;
        }
        return this.http.get<User>(data).pipe(retry(2), catchError(this.handleError));
    }

    getCountryCode(): Observable<any> {
        return this.http.get<any>(this.countryCode).pipe(retry(2), catchError(this.handleError));
    }

    // getAllAuditYr(id): Observable<any> {
    //   return this.http
    //     .get<any>(this.getPRAuditYearDropdown + '/' + id)
    //     .pipe(
    //       retry(2),
    //       catchError(this.handleError)
    //     )
    // }
    getAllAuditYr(id, country): Observable<any> {
        let list = '';
        if (country === 'CL') {
            list = this.getPRAuditYearDropdown + '/' + id;
        } else if (country === 'MX') {
            //  if (id === 'Scan Allowances') { list = this.getmxscaudityr }
            list = this.getmxscaudityr + '/' + id;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    getVendorandSapDetails(id): Observable<any> {
        let list = this.getVendorDetails + '/' + id;
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    // editDataManagePR(editedData, pageID, RECORD_ID): Observable<EditDetails> {
    //   return this.http.
    //     post<EditDetails>(this.editDataMPR + '/' + pageID, JSON.stringify(editedData), this.httpOptions)

    // }
    editDataManagePR(editedData, pageID, country): Observable<EditDetails> {
        let list = '';
        if (country === 'CL') {
            list = this.editDataMPR + '/' + pageID;
        } else if (country === 'MX') {
            list = this.addrecmxsc + '/' + pageID;
        }
        return this.http.post<EditDetails>(list, JSON.stringify(editedData), this.httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    editData(payload, pageID) {
        let list = '';
        list = this.saveDataAmount + '/' + pageID;

        return this.http.post<EditDetails>(list, JSON.stringify(payload), this.httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    // bulkDataUpload(editedData, pageID): Observable<EditDetails> {
    //  return this.http.
    //     post<EditDetails>(this.bulkUpload + '/' + pageID, JSON.stringify(editedData), this.httpOptions)
    // }

    bulkDataUpload(editedData, pageID, CCode): Observable<EditDetails> {
        let list = '';
        if (CCode === 'CL') {
            list = this.bulkUpload + '/' + pageID;
        } else if (CCode === 'MX') {
            list = this.addrecmxsc + '/' + pageID;
        }
        return this.http.post<EditDetails>(list, JSON.stringify(editedData), this.httpOptions);
    }

    addNewRow(editedData, pageID, CCode): Observable<EditDetails> {
        let list = '';
        if (CCode == 'MX') {
            list = this.addrecmxsc + '/' + pageID;
        } else {
            list = this.addNewDataRow + '/' + pageID;
        }
        // EditDataRow/<id>/<recordid>
        return this.http.post<EditDetails>(list, JSON.stringify(editedData), this.httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    addNewRowData(editedData, pageID, CCode): Observable<EditDetails> {
        let list = '';

        list = this.addNewDataRowData + '/' + pageID;

        // EditDataRow/<id>/<recordid>
        return this.http.post<EditDetails>(list, JSON.stringify(editedData), this.httpOptions).pipe(retry(1), catchError(this.handleError));
    }
    // getAuditorList(): Observable<any> {
    //   return this.http
    //     .get<any>(this.auditorList)
    //     .pipe(
    //       retry(2),
    //       catchError(this.handleError)
    //     )
    // }

    getAuditorList(pageID, CCode): Observable<any> {
        let list = '';
        if (CCode == 'MX') {
            // if (pageID == 'Scan Allowances') {
            list = this.getmxscauditorlist;
            //  }
        } else {
            list = this.auditorList;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    getAuditorStatusList(ccCode): Observable<any> {
        return this.http.get<any>(this.changeStatus + '/' + ccCode).pipe(retry(2), catchError(this.handleError));
    }

    assignPersonAuditor(editedData, pageID, recordId): Observable<EditDetails> {
        // EditDataRow/<id>/<recordid>
        return this.http.post<EditDetails>(this.assignAuditor + '/' + pageID + '/' + recordId, JSON.stringify(editedData), this.httpOptions);
    }

    changeAuditorStatus(editedData, pageID, recordId): Observable<EditDetails> {
        return this.http.post<EditDetails>(this.changePRAuditorStatus + '/' + pageID + '/' + recordId, JSON.stringify(editedData), this.httpOptions);
    }

    getClaimsDataByID(pageID, recordId, ccCode): Observable<any> {
        let list = '';
        if (ccCode == 'MX') {
            //  if (pageID == 'Scan Allowances') {
            list = this.getClaimsDataMX + '/' + pageID + '/' + recordId;
            //  }
        } else {
            list = this.getClaimsData + '/' + pageID + '/' + recordId;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    getMXDatadropdowns(tableName) {
        return this.http.get<any>(this.getlookupmx + '/ScanAllowances').pipe(retry(2), catchError(this.handleError));
    }

    oldClaimsData(claimId, countyrCode) {
        return this.http.get<any>(this.oldClaimApI + '/' + claimId + '/' + countyrCode).pipe(retry(2), catchError(this.handleError));
    }

    getClaimsDropdowns(ccCode, pageID) {
        let list;
        if (ccCode === 'CL') {
            list = this.claimsDropDowns + '/' + ccCode;
        } else if (ccCode === 'MX') {
            list = this.claimsDropDowns + '/' + ccCode;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    sendClaimsData(data, clCode, sendclaimType): Observable<EditDetails> {
        let list;
        if (clCode === 'CL') {
            list = this.addClaimsData + '/' + sendclaimType;
        } else if (clCode === 'MX') {
            list = this.addClaimsDataMX + '/' + sendclaimType;
        }

        return this.http.post<EditDetails>(list, JSON.stringify(data), this.httpOptions);
    }

    getDepartmentNum() {
        return this.http.get<User>(this.getDepartMentDropDown).pipe(retry(2), catchError(this.handleError));
    }

    fileUpload(payload: any, response) {
        const formData = new FormData();
        formData.append('files', payload.file);
        return this.http.post(this.fileUploadAPI + '/' + response, payload.file);
    }
    getDataDropdown(ccCode) {
        return this.http.get<any>(this.dataDropDown + '/' + ccCode).pipe(retry(2), catchError(this.handleError));
    }
    getCurrentTitle() {
        return this.title.getTitle();
    }
    setCurrentTitle(titleName: string) {
        this.title.setTitle(titleName);
    }

    

    // paybackClaim(data, country): Observable<EditDetails> {
        
    //     const sanitizedData = {
    //         ...data,
    //         Credit_Memo_Desc: this.sanitizeCreditMemo(data.Credit_Memo_Desc)
    //     };
    //     return this.http.post<EditDetails>(this.paybackclaim + '/' + country,JSON.stringify(sanitizedData),this.httpOptions);
        
    // }
    
    // private sanitizeCreditMemo(desc: string): string {
        
    //     return desc; 
    // }
    

    getPaybackDatainClaims(clamiId): Observable<any> {
        return this.http.get<any>(this.paybackclaimsData + '/' + clamiId).pipe(retry(2), catchError(this.handleError));
    }

    getAllClaimsData(country): Observable<any> {
        return this.http.get<any>(this.getAllClaimData + '/' + country).pipe(retry(2), catchError(this.handleError));
    }

    getDataTable(country, Id, pageNumber, auditYear, monthValue, keyName, keyOrder): Observable<any> {
        console.log("datatable",  Id)
        if (auditYear == 'All') {
            auditYear = '2018,2019,2020,2021,2022,2023';
        }
        if (country == 'MX') {
            keyName = 'FILEYEAR';
            keyOrder = 0;
            if (Id === 'PPA_SCAN_REBATE_DEDUCTIONS') {
                keyName = 'YEAR_MONTH';
            } else if (Id === 'PPA_MX_POS_WM' || Id === 'PPA_MX_POS_SAMS' || Id === 'PPA_NEW_STORE_LIST_M' || Id === 'PPA_STORE_SHIPS_DWH' || Id === 'PPA_VOLUME_ALLOWANCE_DETAIL' || Id === 'PPA_VOLUME_ALLOWANCE_DEDUCTION' ||  Id === 'PPA_MARKDOWN_DETAIL' || Id === 'PPA_MARKDOWN_PRICE_HISTORY') {
                keyName = 'AUDIT_YEAR';
            }
            return this.http.get<any>(this.dataMxModuleTable + '/' + Id + '/' + auditYear + '/'  + monthValue + '/' + pageNumber + '/' + keyName + '/' + keyOrder).pipe(retry(2), catchError(this.handleError));
        } else {
            return this.http.get<any>(this.dataModuleTable + '/' + Id + '/' + auditYear + '/' + pageNumber + '/' + keyName + '/' + keyOrder).pipe(retry(2), catchError(this.handleError));
        }
    }

  
    

    getPointOfSaleData(country, auditYear, month, pageNumber, keyName, keyOrder, pageId) {
        console.log("pointofsale",  pageId)
        if (country === 'CL') {
            return this.http

                .get<any>(this.getPontOfSaleUrl + '/' + auditYear + '/' + month + '/' + pageNumber + '/' + keyName + '/' + keyOrder)

                .pipe(
                    retry(2),

                    catchError(this.handleError)
                );
        } else {
            return this.http

                .get<any>(this.getPontOfSaleUrlMX + '/' + pageId + '/' + auditYear + '/' + month + '/' + pageNumber + '/' + keyName + '/' + keyOrder)

                .pipe(
                    retry(2),

                    catchError(this.handleError)
                );
        }
    }
    // checkHistory(auditType, RecoedID): Observable<any> {
    //   return this.http
    //     .get<any>(this.checkHistoryAPI + '/' + auditType + '/' + RecoedID)
    //     .pipe(
    //       retry(2),
    //       catchError(this.handleError)
    //     )
    // }

    checkHistory(ccCode, auditType, RecoedID): Observable<any> {
        let list;
        if (ccCode === 'CL') {
            list = this.checkHistoryAPI + '/' + auditType + '/' + RecoedID;
        } else if (ccCode === 'MX') {
            list = this.gethistmxsc + '/' + auditType + '/' + RecoedID;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    claimTemplateUploadData(claim_id, username, country): Observable<any> {
        // claimTemplateUploadMX
        // return this.http
        //   .get<any>(this.claimTemplateUpload + "/" + claim_id + '/' + username)
        //   .pipe(
        //     retry(2),
        //     catchError(this.handleError)
        //   )

        let list;
        if (country === 'CL') {
            list = this.claimTemplateUpload + '/' + claim_id + '/' + username;
        } else if (country === 'MX') {
            list = this.claimTemplateUploadMX + '/' + claim_id + '/' + username;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    // claimVolumeTemplateUploadData(claim_id: string, username: string, country: string, filenames: string[]): Observable<any> {
    //     let url: string;
    //     if (country === 'CL') {
    //         url = this.claimVolumeTemplateUpload + '/' + claim_id + '/' + username;
    //     } else if (country === 'MX') {
    //         url = this.claimVolumeTemplateUploadMX + '/' + claim_id + '/' + username;
    //     }
    
        
    //     const payload = { filenames: filenames };
    
        
    //     return this.http.post<any>(url, payload).pipe(
    //         retry(2),
    //         catchError(this.handleError)
    //     );
    // }

    claimVolumeTemplateUploadData(claim_id: string, username: string, country: string, filenames: string[]): Observable<any> {
        let url: string;
        if (country === 'CL') {
            url = `${this.claimVolumeTemplateUpload}/${claim_id}/${username}`;
        } else if (country === 'MX') {
            url = `${this.claimVolumeTemplateUploadMX}/${claim_id}/${username}`;
        }

        const payload = { filenames: filenames };

        return this.http.post<any>(url, payload).pipe(
            retry(0), // Ensure no retries
            catchError((error) => this.handleError(error))
        );
    }

    


    


    getFileDetails(claim_id): Observable<any> {
        return this.http.get<any>(this.getFileDataByClaimId + '/' + claim_id).pipe(retry(2), catchError(this.handleError));
    }

    getClaimsById(claim_id): Observable<any> {
        return this.http.get<any>(this.paybackclaimsData + '/' + claim_id).pipe(retry(2), catchError(this.handleError));
    }

    sendBulkUpdate(data, pageID, auditYear, ccCode): Observable<EditDetails> {
        if (ccCode === 'CL') {
            return this.http.post<EditDetails>(this.BulkUpdateData + '/' + pageID + '/' + auditYear, JSON.stringify(data), this.httpOptions);
        } else {
            return this.http.post<EditDetails>(this.BulkUpdateDataMx + '/' + pageID + '/' + auditYear, JSON.stringify(data), this.httpOptions);
        }
    }

    filterBulkUpdate(data, pageID, auditYear, ccCode): Observable<EditDetails> {
        if (ccCode === 'MX') {
            return this.http.post<EditDetails>(this.filterBulkUpdateMX + '/' + pageID, JSON.stringify(data), this.httpOptions);
        } else {
            return this.http.post<EditDetails>(this.filterBulkUpdateCL + '/' + pageID, JSON.stringify(data), this.httpOptions);
        }
    }

    // getAuditYrData(Id): Observable<any> {
    //   return this.http
    //     .get<any>(this.getAuditYearData + '/' + Id)
    //     .pipe(
    //       retry(2),
    //       catchError(this.handleError)
    //     )
    // }
    getAuditYrData(Id, country): Observable<any> {
        let list = '';
        if (country === 'CL') {
            list = this.getAuditYearData + '/' + Id;
        } else if (country === 'MX') {
            // if(Id==='Scan Allowances'){
            list = this.getmxscaudityr;
            // }
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    getPdfFileDetails(id): Observable<any> {
        return this.http.get<any>(this.getFilePreview + '/' + id).pipe(retry(2), catchError(this.handleError));
    }

    getCalculatedValue(payload) {
        return this.http.post<User>(this.getCalculatedFieldsNewStore, payload).pipe(retry(2), catchError(this.handleError));
    }
    getCalculatedValueByVolume(payload) {
        return this.http.post<User>(this.getCalculatedFieldsVolume, payload).pipe(retry(2), catchError(this.handleError));
    }

    dataPageFilters(payload, tableName, pageNo, keyName, keyOrder): Observable<any> {
        return this.http.post<any>(this.dataFilters + '/' + tableName + '/' + pageNo + '/' + keyName + '/' + keyOrder, JSON.stringify(payload), this.httpOptions);
    }

    dataPageFiltersMX(payload, tableName, pageNo, keyName, keyOrder): Observable<any> {
        return this.http.post<any>(this.dataFiltersMs + '/' + tableName + '/' + pageNo + '/' + keyName + '/' + keyOrder, JSON.stringify(payload), this.httpOptions);
    }

    qrPageFiltersMX(country, payload, tableName, auditYear, page, key, order) {
        let list = '';
        let sorter = '';
        if (country === 'CL') {
            list = this.auditFilterCl;
            sorter = 'RecordId';
        } else {
            list = this.auditFilterMx;
            sorter = 'RECORD_ID';
        }
        return this.http.post<any>(list + '/' + tableName + '/' + auditYear + '/' + page + '/' + sorter + '/' + order, JSON.stringify(payload), this.httpOptions);
    }

    getColumnSchemas(tableName, country) {
        let list;
        if (country === 'CL') {
            list = this.columnSchemasAPICL;
        } else {
            list = this.columnSchemasAPIMX;
        }
        return this.http.get<any>(list + '/' + tableName).pipe(retry(2), catchError(this.handleError));
    }

    pivotTable(tableName, payload, country) {
        let list;
        if (country === 'CL') {
            list = this.pivotTableAPICL;
        } else {
            list = this.pivotTableAPIMX;
        }
        console.log()
        return this.http.post<any>(list + '/' + tableName, JSON.stringify(payload), this.httpOptions).pipe(catchError(this.handleError));
    }
    // updateClaimPageDate(data): Observable<any> {
    //   return this.http.
    //     post<any>(this.claimPackageUpdate, JSON.stringify(data), this.httpOptions)
    // }

    updateClaimPageDate(data, country): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/zip'
        });
        let options = {
            headers: headers
        };
       

        let list = '';
        if (country === 'CL') {
            list = this.claimPackageUpdate;
        } else if (country === 'MX') {
            
            list = this.claimPackageUpdateMX;
            
        }

        return this.http
            .post(list, JSON.stringify(data), {
                headers: new HttpHeaders({
                    Authorization: '{data}',
                    'Content-Type': 'application/json'
                }),
                responseType: 'blob',

                observe: 'body'
            })
            .pipe(
                tap(
                    
                    (data) => console.log('You received data'),
                    (error) => console.log(error)
                )
            );
    }

   


        

    claimNumberGeneration(year, ccCode): Observable<any> {
        let list;
        if (ccCode === 'CL') {
            list = this.claimNoGeneration;
        } else if (ccCode === 'MX') {
            list = this.claimNoGenerationMX;
        }
        return this.http.get<any>(list + '/' + year).pipe(retry(2), catchError(this.handleError));
    }

    gethistorydata(ccCode, audittype, recordid) {
        let list;
        if (ccCode === 'CL') {
            list = this.gethistory + '/' + audittype + '/' + recordid;
        } else if (ccCode === 'MX') {
            list = this.gethistmxsc + '/' + recordid;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    getSelectedPRdetails(ccCode, audittype, claimID) {
        let list;
        if (ccCode === 'CL') {
            list = this.getAuditDataByClaimIDPR + '/' + audittype + '/' + claimID;
        } else if (ccCode === 'MX') {
            list = this.getAuditDataByClaimIDMXPR + '/' + audittype + '/' + claimID;
        }
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    getmxcalc(code): Observable<any> {
        return this.http.post<EditDetails>(this.getmxsccalc, code);
    }

    getmxVolcalc(code): Observable<any> {
        return this.http.post<EditDetails>(this.getmxVolumecalc, code);
    }

    getmxfillRatecalc(code): Observable<any> {
        return this.http.post<EditDetails>(this.getmxfrcalc, code);
    }

    getMarkdownChartData(code): Observable<any> {
        return this.http.post<EditDetails>(this.getChartData, code);
    }

    getmxmarkdowncalc(code): Observable<any> {
        return this.http.post<EditDetails>(this.getmxmarkcalc, code);
    }
    

    getmxMSIRatecalc(code): Observable<any> {
        return this.http.post<EditDetails>(this.getmxMSIcalc, code);
    }

    getmxitemnbr(year): Observable<any> {
        return this.http.get<any>(this.getmxscitmnbr + year).pipe(retry(2), catchError(this.handleError));
    }

    getmxvendorlist(year): Observable<any> {
        return this.http.get<any>(this.getmxscvendorlist + year).pipe(retry(2), catchError(this.handleError));
    }

    getmxformatslist(tableName): Observable<any> {
        return this.http.get<any>(this.germxformats + '/' + tableName).pipe(retry(2), catchError(this.handleError));
    }

    getmultiplecaimDetails(tableName, payload, country) {
        if (country == 'MX') {
            return this.http.post<any>(this.multipleclaimsMX + '/' + tableName, JSON.stringify(payload), this.httpOptions);
        } else {
            return this.http.post<any>(this.multipleclaimsCL + '/' + tableName, JSON.stringify(payload), this.httpOptions);
        }
    }

    exportFilter(payload, tableName, pageNo, country): Observable<any> {
        let list;
        if (country === 'CL') {
            list = this.exportAdvanceFilter;
        } else if (country === 'MX') {
            list = this.exportAdvanceFilterMX;
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let options = {
            headers: headers
        };
        return this.http
            .post(list + '/' + tableName + '/' + pageNo, JSON.stringify(payload), {
                headers: new HttpHeaders({
                    Authorization: '{data}',
                    'Content-Type': 'application/json',
                    'file-name': 'salesReport.xls '
                }),
                responseType: 'blob',

                observe: 'body'
            })
            .pipe(
                tap(
                    // Log the result or error
                    (data) => console.log('You received data'),
                    (error) => console.log(error)
                )
            );
    }

    exportFilterNormal(tableName, auditYear, pageNo, country): Observable<any> {
        let list;
        if (country === 'CL') {
            list = this.exportNormal;
        } else if (country === 'MX') {
            list = this.exportNormalMX;
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let options = {
            headers: headers
        };
        return this.http
            .post(list + '/' + tableName + '/' + auditYear + '/' + pageNo, JSON.stringify(auditYear), {
                headers: new HttpHeaders({
                    Authorization: '{data}',
                    'Content-Type': 'application/json',
                    'file-name': 'salesReport.xls '
                }),
                responseType: 'blob',

                observe: 'body'
            })
            .pipe(
                tap(
                    // Log the result or error
                    (data) => console.log('You received data'),
                    (error) => console.log(error)
                )
            );
    }

    qrPageExport(tableName, auditYear, pageNo, country, payload, language, key, sortOrder): Observable<any> {
        let lang = language.toUpperCase();
        console.log(lang);
        let list = this.exportAuditMX;
        // if (country === 'CL') {
        //     list = this.exportNormal;
        // } else if (country === 'MX') {
        //     list = this.exportAuditMX;
        // }
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let options = {
            headers: headers
        };
        return this.http
            .post(list + '/' + tableName + '/' + auditYear + '/' + pageNo + '/'  + lang + '/' + key + '/' + sortOrder, JSON.stringify(payload), {
                headers: new HttpHeaders({
                    Authorization: '{data}',
                    'Content-Type': 'application/json',
                    'file-name': 'salesReport.xls '
                }),
                responseType: 'blob',

                observe: 'body'
            })
            .pipe(
                tap(
                    // Log the result or error
                    (data) => console.log('You received data'),
                    (error) => console.log(error)
                )
            );
    }

    getvendornameinData(vendorNum): Observable<any> {
        return this.http.get<any>(this.vendordetailsData + '/' + vendorNum).pipe(retry(2), catchError(this.handleError));
    }

    getFormatsForDWH(pageID) {
        let list;
        list = this.formatforDWHtables + '/' + pageID;
        return this.http.get<any>(list).pipe(retry(2), catchError(this.handleError));
    }

    getVolumeAllowanceDetailForDWH(code): Observable<any> {
        console.log(code)
        return this.http.post<any>(this.volumeAllowanceDetail, code);
    }

    getMXItemForWDH(code): Observable<any> {
        return this.http.post<any>(this.volumeItemDetail, code);
    }
}
