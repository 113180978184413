import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';

import { ActivatedRoute, Router } from '@angular/router';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AssignAuditorComponent } from '../popup/assign-auditor/assign-auditor.component';
import { MatDialog } from '@angular/material/dialog';
import { AddRowComponent } from '../popup/add-row/add-row.component';
import { Location } from '@angular/common';
import { apiService } from 'src/app/_services/app.service';
import { AuditServiceService } from 'src/app/_services/audit-service.service';

@Component({
    selector: 'app-assign-history',
    templateUrl: './assign-history.component.html',
    styleUrls: ['./assign-history.component.css']
})
export class AssignHistoryComponent implements OnInit {
    auditTableType: any;
    auditTableData: any;
    auditTypeID: any;
    ID: any;
    auditDropDown: any;
    selecteddata: any;
    clCode: any;
    public rowSelection = 'multiple';
    private gridApi!: GridApi;

    dummyData: any;
    recordID: any;
    columnDefs;
    rowData;

    country = '';
    flag = '';
    country_cd = '';
    sanitizer: any;
    clcode: string;
    constructor( private apiservice: apiService, private http: HttpClient, private auditService: AuditServiceService, public activatedRoute: ActivatedRoute, public dialog: MatDialog, public router: Router) {
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
    }

    safeRedirect(urlSegments: string[]) {
        const url = urlSegments.join('/'); 
        
        
    }

    nullHandler(params) {
        return params.value === null ? '-' : params.value;
    }
    ngOnInit() {
        this.country_cd = localStorage.getItem('country');
        this.countryfields();
        this.clCode = this.country_cd;
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
        this.recordID = this.activatedRoute.snapshot.params['recordID'];
        this.loadAuditTypeValues(this.clCode);
    }

    countryfields() {
        if (this.country_cd === 'CL') {
            this.country = 'Chile';
            this.flag = '../assets/img/chile.png';
        } else if (this.country_cd === 'MX') {
            this.country = 'Mexico';
            this.flag = '../assets/img/mexico.png';
        } else {
            this.country = '';
            this.flag = '';
        }
    }

    backClicked() {
        this.safeRedirect(['dashboard', this.clCode]);
    }

    homeIcon() {
        this.router.navigate(['dashboard/' + '/' + this.clCode]);
    }

    loadAuditTypeValues(CLCode) {
        this.apiservice.getAuditType(CLCode).subscribe((response) => {
            this.auditDropDown = response;
            this.selecteddata = this.auditTypeID;
        });
    }

    selectedValue(auditTypeId: any) {
        this.apiservice.gethistorydata(this.clCode, auditTypeId, this.recordID).subscribe((response) => {
            this.rowData = response;
        });
        this.router.navigate(['assign-history/' + this.clCode + '/' + auditTypeId + '/' + this.recordID]);
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridApi.hideOverlay();
        this.apiservice.checkHistory(this.clCode, this.auditTypeID, this.recordID).subscribe((result) => {
            this.rowData = result;

            if (this.rowData) {
                this.columnDefs = this.generateColumns(this.rowData);
            }
        });
    }

    generateColumns(data: any[]) {
        let columnDefinitions = [];

        data.map((object) => {
            Object.keys(object).map((key) => {
                let mappedColumn = {
                    headerName: key.toUpperCase(),
                    field: key,
                    sortable: true,
                    filter: true,
                    filterParams: filterParams,
                    width: 130
                };

                columnDefinitions.push(mappedColumn);
            });
        });
        //Remove duplicate columns
        columnDefinitions = columnDefinitions.filter((column, index, self) => index === self.findIndex((colAtIndex) => colAtIndex.field === column.field));
        return columnDefinitions;
    }
}

var filterParams = {
    suppressAndOrCondition: false,
    filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
    buttons: ['reset', 'apply'],
    closeOnApply: true,
    defaultJoinOperator: 'OR'
};
