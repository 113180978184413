import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateClaimModal } from '../../../_models/createClaim';
import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { Location } from '@angular/common';

export interface DialogData {
    requestId: any;
}

@Component({
    selector: 'app-create-payback',
    templateUrl: './create-payback.component.html',
    styleUrls: ['./create-payback.component.css']
})
export class CreatePaybackComponent implements OnInit {
    PayBackClaim: CreateClaimModal;
    @Output() createUsercontact = new EventEmitter<CreateClaimModal>();
    ClaimsData: any;
    claimNumber: any;
    claimDate: any;
    claimStatus: any;
    originalClaimAmt: any;
    payBackForm: FormGroup;
    claimsdataValues: any;
    creditMemo: any;
    creditMemoCode: any = [];
    clCode: any;
    pageID: any;

    constructor(
        public dialogRef: MatDialogRef<CreatePaybackComponent>,
        @Inject(MAT_DIALOG_DATA) public datas: DialogData,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private apiservice: apiService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.SetLang();
        this.ClaimsData = this.datas.requestId.data;
        this.clCode = this.datas.requestId.countryCode;
        this.pageID = this.datas.requestId.id;
        console.log("this.ClaimsData ", this.ClaimsData);
        this.getValues();
        this.getAllDropdownValues(this.clCode);
        this.loadAuditTypeValues(this.ClaimsData.APP_CLAIM_NUMBER);

        this.payBackForm = this.formBuilder.group({
            Payback_Document_Number: [, Validators.required],
            Payback_Amount: [, Validators.required],
            Tax_IVA: [, Validators.required],
            Tax_EPS: [, Validators.required],
            Total_Payback: [, Validators.required],
            Credit_Memo_Code: [, Validators.required],
            Credit_Memo_Desc: ['', Validators.required]
        });
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }

    getValues() {
        this.claimNumber = this.ClaimsData.APP_CLAIM_NUMBER;
        this.claimDate = this.ClaimsData.DATE_AUDITED;
        this.claimStatus = this.ClaimsData.Claim_Status;
        this.originalClaimAmt = this.ClaimsData.Original_Claim_Amt;
    }

    get f() {
        return this.payBackForm.controls;
    }

    loadAuditTypeValues(claimId: string) {
        this.apiservice.getPaybackDatainClaims(claimId).subscribe((response) => {
            this.claimsdataValues = response;
        });
    }

    getAllDropdownValues(CLCode: string) {
        this.apiservice.getClaimsDropdowns(CLCode, this.pageID).subscribe((response) => {
            this.creditMemo = response;

            for (const item of this.creditMemo) {
                if (item.Lookup_Code === 'MEMO_CODE') {
                    this.creditMemoCode.push(item.Lookup_Value_Meaning);
                }
            }
        });
    }

    onSubmit() {
        // this.apiservice.paybackClaim(this.payBackForm.value, this.clCode).subscribe((response) => {
        //     if (response[0].response === 'ok') {
        //         this.safeRedirect(); 
        //     } else {
        //         alert('Something went wrong');
        //     }
        // });
    }

    // // Safe redirect method
    // private safeRedirect() {
    //     const destination = this.route.snapshot.url.join('/'); 
    //     if (this.isValidRedirectUrl(destination)) {
    //         this._location.back(); 
    //     } else {
    //         console.error('Invalid redirect URL:', destination);
    //         this.router.navigate(['/safe-path']); 
    //     }
    // }

    private isValidRedirectUrl(url: string): boolean {
        const safePaths = ['/safe-path', '/another-safe-path']; 
        return url.startsWith(window.location.origin) || safePaths.includes(url);
    }
}
