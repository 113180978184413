import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { apiService } from 'src/app/_services/app.service';
import { Chart } from 'angular-highcharts';
import _ from 'lodash';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})

export class ChartComponent implements OnInit {
  tableData: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiservice: apiService,
    private dialogRef: MatDialogRef<ChartComponent>,
  ) {}

  chart;
  errorMessage: string;
  enableChart = false;;
    

  ngOnInit() {
    console.log('Dialog Data:', this.data);
    if (this.data) {
      this.processData(); 
    } else {
      this.errorMessage = 'No data provided.';
      this.enableChart = false;
    }
  }
   

  processData() {
      this.chart = new Chart(this.data.chartData);
      console.log(this.chart) 
      this.enableChart = true;
      this.tableData = this.data.tableData;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
