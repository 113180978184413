import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, FirstDataRenderedEvent, GridApi, GridReadyEvent, ITextFilterParams, RowSelectedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { AuditServiceService } from 'src/app/_services/audit-service.service';
import { apiService } from 'src/app/_services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MatDialog } from '@angular/material/dialog';
import { EditDataComponent } from '../popup/edit-data/edit-data.component';
import { ChartComponent } from '../popup/charts/chart.component';
import { ChangeStatusComponent } from '../popup/change-status/change-status.component';
import { DuplicateDataComponent } from '../popup/duplicate-data/duplicate-data.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridHeaderSelectComponent } from '.././grid-header-select.component';
import { UserService, AuthenticationService } from '../../_services';
import { LoginUser } from '../../_models';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import _ from 'lodash';
import { MiscellaneousPRComponent } from '../popup/miscellaneous-pr/miscellaneous-pr.component';
import { CommaFilterComponent } from '../popup/comma-filter/comma-filter.component';
import { TranslateService } from '@ngx-translate/core';
import * as fs from 'file-saver';

@Component({
    selector: 'app-manage-pr',
    templateUrl: './manage-pr.component.html',
    styleUrls: ['./manage-pr.component.css']
})
export class ManagePRComponent implements OnInit {
    auditTableType: any;
    auditTableData: any;
    auditTypeID: any;
    ID: any;
    auditDropDown: any;
    selecteddata: any;
    clCode: any;
    public rowSelection = 'multiple';
    key = 'AUDIT_YEAR';
    private gridApi!: GridApi;
    private gridApi1!: GridApi;
    currentYear = new Date().getFullYear();   
    TotalRecords: any;
    // public rowData!: any[];
    advanceFilterPayload = {};
    advanceFilterEnabled = false;
    dummyData: any;
    defaultColDef;

    fileteredResult = [];
    suppressRowClickSelection = true;
    pagination = true;
    paginationPageSize = 10;

    loading = false;
    currentUser: LoginUser;
    userFromApi: LoginUser;
    createClaimBtn = 'block';
    createMiscellaneous = 'none';
    columnDefs;
    rowData = [];
    pageNumber = -1;
    nextDisabled = false;
    inputDisabled = false;
    prvDisabled = true;
    showLoader = false;
    numberOfPage = 0;
    totalNumber = 0;
    finalData = [];
    auditYear = new Date().getFullYear()-1;
    auditYearsData = [
        {
            "AuditYear": 2021
        },
        {
            "AuditYear": 2022
        },
        {
            "AuditYear": 2023
        },
        {
            "AuditYear": 2024
        }
    ];
    selecetedClaims = [];
    multpleClaims: boolean = false;
    btnCount = [];
    keyOrder = 0;
    rowData1 = [];
    multipleData: any = [];
    columnDefs1;
    rowSelection1 = 'multiple';
    country = '';
    flag = '';
    country_cd = '';
    totalNumberFilter = 0;
    exportBtnList = [];
    gridOptions = {};
    showPagination = true;
    hideColumnFlag: boolean;
    columnName = [];
    columnNameandKey = [];
    chart: any;


    constructor(private userService: UserService, private authenticationService: AuthenticationService, private apiservice: apiService, private http: HttpClient, private auditService: AuditServiceService, public activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar, private translate: TranslateService) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
        this.defaultColDef = {
            width: 100,
            //checkboxSelection: isFirstColumn,
            resizable: true
        };
    }

    callAPiStack;

    nullHandler(params) {
        return params.value === null ? '-' : params.value;
    }

    redirectTo(url: string): void {
        
        if (this.isSafeUrl(url)) {
        //   this._location.go(url); 
        } else {
          console.error('Attempted to redirect to an unsafe URL:', url);
          
        }
      }
    
      private isSafeUrl(url: string): boolean {
        
        return url.startsWith('/') && !url.includes('..');
      }

    numberWithCommas(x) {
        if (x.value != null) {
            const numberValue = x.value;
            const convertNumber = Number(numberValue);
            return numberValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
        }
    }

    // numberWithCommasMX(x) {
    //     if (x.value != null) {
    //         const numberValue = x.value;
    //         if (x.colDef.field == 'POS_SALES_QTY' || x.colDef.field == 'AMS_QTY') {
    //             return numberValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    //         } else {
    //             const convertNumber = Number(numberValue);
    //             return convertNumber.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    //         }
    //     }
    // }

    ngOnInit() {
        this.TotalRecords = [];
        // this.spinnerService.show();
        this.country_cd = localStorage.getItem('country');
        this.countryfields();
        this.onPageLoad();
        //this.clCode = this.activatedRoute.snapshot.params["CL"];
        this.clCode = this.country_cd;
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];

        this.loadAuditTypeValues(this.clCode);
        this.currentUser = this.authenticationService.currentUserValue;
        this.showBtnUser(this.currentUser.role);
        if (this.auditTypeID == 'MISC') {
            this.createMiscellaneous = 'inline-block';
        } else {
            this.createMiscellaneous = 'none';
        }
        if (this.auditTypeID == 'Markdown') {
            this.auditYear = 2022;
        }
    }

    countryfields() {
        if (this.country_cd === 'CL') {
            this.country = 'Chile';
            this.flag = '../assets/img/chile.png';
        } else if (this.country_cd === 'MX') {
            this.country = 'Mexico';
            this.flag = '../assets/img/mexico.png';
        } else {
            this.country = '';
            this.flag = '';
        }
    }

    getAllAuditYrData() {

        // this.apiservice.getAllAuditYr(this.auditTypeID, this.country_cd).subscribe((result: any) => {
        //     if (this.country_cd === 'MX') {
        //         let temp;
        //         for (let i = 0; i < result.length; i++) {
        //             result = _.sortBy(result, (itm) => {
        //                 return itm.AUDIT_YEAR;
        //             });
        //             temp = {
        //                 AuditYear: result[i].AUDIT_YEAR
        //             };
        //             this.auditYearsData[i] = temp;
        //         }
        //         this.auditYearsData.sort().reverse();
        //     } else {
        //         this.auditYearsData = result;
        //     }
        //     console.log(this.auditYearsData)
        // });

    }

    // backClicked() {
    //     this._location.back();
    // }

    onPageLoad() {
        this.loading = true;
        this.userService
            .getById(this.currentUser.id)
            .pipe(first())
            .subscribe((user) => {
                this.loading = false;
                this.userFromApi = user;
            });
    }

    showBtnUser(user) {
        if (user.toLowerCase() == 'auditor' || user.toLowerCase() == 'systemUser' || user.toLowerCase() == 'admin' || user.toLowerCase() == 'ppamanager') {
            this.createClaimBtn = 'block';
        } else {
            this.createClaimBtn = 'none';
        }
    }

    onSelectionChanged(params: GridReadyEvent) {
        var selectedRows = this.gridApi.getSelectedRows();

        (document.querySelector('#selectedRows') as any).innerHTML = '<b>Rows selected-</b>' + selectedRows.length;
        if (this.gridApi) {
            this.gridApi.hideOverlay();
            this.gridApi.refreshHeader();
        }
    }

    onFilterChanged() {
        this.TotalRecords = this.gridApi.getDisplayedRowCount();
    }

    onPaginationChanged(params: GridReadyEvent) {
        if (this.gridApi) {
            this.gridApi.hideOverlay();
            this.gridApi.refreshHeader();
        }
    }

    homeIcon() {
        this.router.navigate(['dashboard/' + '/' + this.clCode]);
    }

    dateKeyObject = ['START_DATE', 'END_DATE'];

    onGridReady(params: GridReadyEvent) {
        this.showLoader = true;
        this.gridApi = params.api; // To access the grids API
        this.gridApi.hideOverlay();
        this.pageNumber++;
        this.numberOfPage++;
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
    }

    allNumberColume = [
        'RELEASE_NUM',
        'SUPPLIER_NUM',
        'DEAL_AGREEMENT_NUM',
        'ACCOUNT_DEPARTMENT',
        'LEGACY_CATEGORY',
        'CATEGORY',
        'YEAR_VOLUME',
        'PERCENTAGE',
        'VOLUME',
        'EARNED',
        'AMOUNT_RECEIVED',
        'FIXED_AMOUNT_AGREEMENT',
        'DEBITED_AMOUNT',
        'AGREEMENT_NUMBER',
        'APP_CLAIM_NUMBER',
        'RECORD_ID',
        'AUDIT_YEAR',
        'DEPT',
        'TOTAL_SALES',
        'STORE_NBR',
        'FIXED_AMT',
        'UFAMT',
        'AMT_DEDUCTED',
        'OTHER_DEDUCTION',
        'AMOUNT_DUE',
        'VENDOR_NBR',
        'SEQUENCING',
        'AMOUNT',
        'AMT_FOR_THE_PERIOD',
        'AMOUNT_DEDUCTED',
        'TOTAL_DEDUCTION',
        'PENDING_DUE',
        'IVA_DUE',
        'TOTAL_PENDING',
        'AGREEMENT_NUM',
        'AMT_DUE',
        'AMS_RATE',
        'ALLOW_EARNED',
        'Accrual_AMT',
        'AMS_QTY',
        'AMS_SALES_AMT',
        'POS_SALES_AMT',
        'IVA_DUE',
        'IEPS_DUE',
        'POS_SALES_QTY',
        'Adjustments',
        'GROUP',
        'SAP_VENDOR',
        'IEPS_PCT',
        'IVA_PCT',
        'CLAIM_NUMBER',
        'NO_OF_DEDUCTION',
        'ENTERED_AMT',
        'AMTDUE',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'TOTAL_DUE',
        'OTHER_DEDUCT',
        'TOTAL_RETAIL_AMT_SHORT',
        'TOTAL_MARGIN_LOST',
        'TOTAL_EARNED',
        'AMOUNT_DEDUCTED',
        'PENDING_DUE',
        'COOP_DEDUCTED',
        'AMT_DEDUCTED',
        'ALL_BODEGA_POS_QTY',
        'ALL_BODEGA_POS_AMT',
        'BASE_COOP_AMT',
        'BASE_AMT_DEDUCT',
        'ADJUSTMENTS',
        'BASE_AMT_DUE',
        'AMT_DEDUCT_TAXES',
        'AMS_PURCHASE',
        'PO_PURCHASES',
        'OTHER_DED',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'PREVIOUS_CLAIMS',
        'IEPS',
        'IVA',
        'AMTDEDUCTED',
        'TOTAL_AMT_PAID',
        'TOTAL_ORD_QTY',
        'TOTAL_RCV_QTY',
        'TOTAL_SHORT_QTY',
        'TOTAL_SHIPS',
        'UNITS_SOLD', 'TOTAL_DEFERRED', 'SURCHARGE_SALES', 'OTHER_DEDUCTIONS', 'DEDUCTION', 'DEDUCTION_CIVA',
        'POS_QTY',
        'POS_SALES',
        'IMPACT_MU/MD',
        'AGRT_AMT',
        'DIFF',
        'VAT',
        'IEPS',
        'Unit Allowance',
        'STD_UNIT_RETAIL',
        'ACT_AVG_RETAIL',
        'STD_UNIT_COST'
    ];
    allNumberWIthComma = [
        'YEAR_VOLUME',
        'VOLUME',
        'TOTAL_DEDUCTION',
        'IVA_DUE',
        'TOTAL_PENDING',
        'FIXED_AMT',
        'TOTAL_SALES',
        'UFAMT',
        'TOTAL_DUE',
        'AMT_DEDUCTED',
        'OTHER_DEDUCTION',
        'AMOUNT_DUE',
        'EARNED',
        'AMOUNT_RECEIVED',
        'FIXED_AMOUNT_AGREEMENT',
        'DEBITED_AMOUNT',
        'AMOUNT',
        'AMT_FOR_THE_PERIOD',
        'AMT_DUE',
        'POS_SALES_QTY',
        'AMS_RATE',
        'ALLOW_EARNED',
        'Accrual_AMT',
        'Adjustments',
        'AMS_QTY',
        'AMS_SALES_AMT',
        'POS_SALES_AMT',
        'IVA_DUE',
        'IEPS_DUE',
        'OTHER_DEDUCT',
        'ENTERED_AMT',
        'AMTDUE',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'TOTAL_RETAIL_AMT_SHORT',
        'TOTAL_MARGIN_LOST',
        'TOTAL_EARNED',
        'AMOUNT_DEDUCTED',
        'PENDING_DUE',
        'COOP_DEDUCTED',
        'AMT_DEDUCTED',
        'ALL_BODEGA_POS_QTY',
        'ALL_BODEGA_POS_AMT',
        'BASE_COOP_AMT',
        'BASE_AMT_DEDUCT',
        'ADJUSTMENTS',
        'BASE_AMT_DUE',
        'AMT_DEDUCT_TAXES',
        'AMS_PURCHASE',
        'PO_PURCHASES',
        'OTHER_DED',
        'IEPS_AMOUNT',
        'IVA_AMOUNT',
        'PREVIOUS_CLAIMS',
        'IEPS',
        'IVA',
        'AMTDEDUCTED',
        'TOTAL_AMT_PAID',
        'TOTAL_ORD_QTY',
        'TOTAL_RCV_QTY',
        'TOTAL_SHORT_QTY',
        'TOTAL_SHIPS', 'UNITS_SOLD', 'TOTAL_DEFERRED', 'SURCHARGE_SALES', 'OTHER_DEDUCTIONS', 'DEDUCTION', 'DEDUCTION_CIVA',
        'POS_QTY',
        'POS_SALES',
        'IMPACT_MU/MD',
        'AGRT_AMT',
        'DIFF',
        'VAT',
        'IEPS',
        'Unit Allowance',
        'STD_UNIT_RETAIL',
        'ACT_AVG_RETAIL',
        'STD_UNIT_COST'
    ];

    convertObject(v) {
        for (const [key, value] of Object.entries(v)) {
            const findIndex = this.allNumberColume.indexOf(key);
            if (findIndex > -1) {
                if (v[key] != undefined) {
                    v[key] = Number(v[key]);
                }
            }
        }
        delete v.DEPARTMENT_NAME;
        //  delete v.INCLUDE;
        return v;
    }

    convertStringToNumberValue(data) {
        if (data != '' && data != null && !isNaN(data)) {
            if (typeof data == 'number') {
                // check if it is integer
                if (Number.isInteger(data)) {
                    return data;
                } else {
                    return data;
                }
            } else {
                const v = parseFloat(data.replace(/,/g, ''));
                return Number(v);
            }
        } else {
            return null;
        }
    }

    numberFilerParams = {
        comparator: function (filterValue: string, cellValue: string) {
            var dateAsString = this.convertStringToNumberValue(cellValue);
            var celToNumber = this.convertStringToNumberValue(filterValue);

            if (dateAsString === celToNumber) {
                return 0;
            }
            if (dateAsString < celToNumber) {
                return -1;
            }
            if (dateAsString > celToNumber) {
                return 1;
            }
        },
        filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        buttons: ['reset', 'apply']
    };

    dateFilterParams = {
        comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split('-');
            var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
        },
        suppressAndOrCondition: false,
        closeOnApply: true,
        filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        browserDatePicker: true,
        buttons: ['reset', 'apply']
    };

    numberStringFilter = {
        filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        textMatcher: ({ filterOption, value, filterText }) => {
            var dataValue = this.convertStringToNumberValue(value);
            var searchValue = this.convertStringToNumberValue(filterText);
            switch (filterOption) {
                case 'equals':
                    return dataValue === searchValue;
                case 'notEqual':
                    return dataValue != searchValue;
                case 'lessThanOrEqual':
                    return dataValue <= searchValue;
                case 'lessThan':
                    return dataValue < searchValue;
                case 'greaterThan':
                    return dataValue > searchValue;
                case 'greaterThanOrEqual':
                    return dataValue >= searchValue;
                default:
                    return false;
            }
        },
        debounceMs: 200,
        suppressAndOrCondition: false,
        buttons: ['reset', 'apply']
    } as ITextFilterParams;

    numberFilterOption = {
        suppressAndOrCondition: false,
        closeOnApply: true,
        filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        buttons: ['reset', 'apply']
    };
    generateColumns(data: any) {
        let columnDefinitions = [];
        for (const [key, value] of Object.entries(data[0])) {
            const v = value;
            const findNumber = _.find(this.allNumberColume, (item) => item === key);
            const keyValue: any = key.toString();
            const convertNumber = Number(v);
            const getData = keyValue.replaceAll('_', ' ');
            const getAry = getData.split(' ');
            const findDate = _.find(getAry, (v) => {
                return v.toLowerCase() === 'date';
            });
            let keyInLowerCase = key.toLowerCase();
            let mappedColumn: any = {
                headerName: this.getTranslation('langtableheaders.' + key),
                field: key,
                filter: this.shouldEnableFilter(),
                sortable: true,
                width: '100%',
                resizable: true
            };
            if (findDate) {
                // mappedColumn.filter = 'agDateColumnFilter';
                // mappedColumn.filterParams = this.dateFilterParams;
                mappedColumn.comparator = this.dateComparator;
            } else if (findNumber) {
                // if (this.clCode == 'CL') {
                const findCommaFilter = this.allNumberWIthComma.indexOf(key);
                if (findCommaFilter > -1) {
                    // mappedColumn.filter = 'agNumberColumnFilter';
                    // mappedColumn.filterParams = this.numberFilterOption;
                    // if (this.clCode == 'CL') {
                    mappedColumn.valueFormatter = this.numberWithCommas;
                    // }
                    // if (this.clCode == 'MX') {
                    //     mappedColumn.valueFormatter = this.numberWithCommasMX;
                    // }
                } else {
                    // mappedColumn.filter = 'agNumberColumnFilter';
                    // mappedColumn.filterParams = this.numberFilterOption;
                }
            }
            if (key == 'MIN_SALES' || key === 'MAX_SALES') {
                // mappedColumn.filter = 'agDateColumnFilter';
                // mappedColumn.filterParams = this.dateFilterParams;
                mappedColumn.comparator = this.dateComparator;
            }
            if (key != 'LAST_UPDATED_BY' && key != 'LAST_UPDATED_DATE' && key != 'VERSION' && key != 'CREATED_DATE') {
                columnDefinitions.push(mappedColumn);
            }
        }
        //Remove duplicate columns
        columnDefinitions = columnDefinitions.filter((column, index, self) => index === self.findIndex((colAtIndex) => colAtIndex.field === column.field));

        return columnDefinitions;
        // return datdef;
    }

    // generateColumns(data: any, columnNames: string[]) {
    //     let columnDefinitions = [];
    //     for (const [key, value] of Object.entries(data[0])) {
    //         const columnName = columnNames[key] || key;
    //         const findNumber = _.find(this.allNumberColume, (item) => item === key);
    //         const keyValue: any = key.toString();
    //         const convertNumber = Number(value);
    //         const getData = columnName.replaceAll('_', ' ');
    //         const getAry = getData.split(' ');
    //         const findDate = _.find(getAry, (v) => {
    //             return v.toLowerCase() === 'date';
    //         });
    //         let keyInLowerCase = key.toLowerCase();
    //         let mappedColumn: any = {
    //             headerName: this.getTranslation(columnName),
    //             field: key,
    //             filter: this.shouldEnableFilter(),
    //             sortable: true,
    //             width: '100%',
    //             resizable: true
    //         };
    //         if (findDate) {
    //             mappedColumn.comparator = this.dateComparator;
    //         } else if (findNumber) {
    //             const findCommaFilter = this.allNumberWIthComma.indexOf(key);
    //             if (findCommaFilter > -1) {
    //                 mappedColumn.valueFormatter = this.numberWithCommas;
    //             }
    //         }
    //         if (key == 'MIN_SALES' || key === 'MAX_SALES') {
    //             mappedColumn.comparator = this.dateComparator;
    //         }
    //         if (key != 'LAST_UPDATED_BY' && key != 'LAST_UPDATED_DATE' && key != 'VERSION' && key != 'CREATED_DATE') {
    //             columnDefinitions.push(mappedColumn);
    //         }
    //     }
    //     // Remove duplicate columns
    //     columnDefinitions = columnDefinitions.filter((column, index, self) => index === self.findIndex((colAtIndex) => colAtIndex.field === column.field));
    
    //     return columnDefinitions;
    // }
    
    // Example usage
    // const columns = this.generateColumns(data, this.columnName);
    

    shouldEnableFilter() {
        if (this.auditTypeID === 'New Stores') {
            return false;
        }
        return true;
    }

    dateComparator(date1: string, date2: string) {
        const date1Number = date1 != undefined ? convertIntoDate(date1).getTime() : 0;
        const date2Number = date2 != undefined ? convertIntoDate(date2).getTime() : 0;
        if (date1Number == null && date2Number == null) {
            return 0;
        }
        if (date1Number == null) {
            return -1;
        }
        if (date2Number == null) {
            return 1;
        }
        return date1Number - date2Number;
    }

    showClaims() {
        let selectedNodes = this.gridApi.getSelectedNodes();

        if (this.clCode == 'MX') {
            if (selectedNodes.length != 0) {
                for (var i = 0; i < selectedNodes.length; i++) {
                    if (this.auditTypeID === 'Scan Allowances' || this.auditTypeID === 'New Stores' ||
                        this.auditTypeID === 'Volume Allowances' || this.auditTypeID === 'UN-DEDUCTED COOPS'
                        || this.auditTypeID == 'MISC' || this.auditTypeID == 'Advertising' || this.auditTypeID == 'Fill Rate'
                        || this.auditTypeID == 'MSI' || this.auditTypeID == 'Low Price' || this.auditTypeID == 'Shortages'  || this.auditTypeID == 'Markdown') {
                        let firstValue = selectedNodes[0].data.SAP_VENDOR;
                        let vendorNumber = selectedNodes[0].data.VENDOR_NBR;
                        if (firstValue == selectedNodes[i].data.SAP_VENDOR && vendorNumber == selectedNodes[i].data.VENDOR_NBR) {
                            this.multpleClaims = true;
                        } else {
                            this.multpleClaims = false;
                            break;
                        }
                    }
                }
                let originalCLaimAmount = 0;
                let taxIVA = 0;
                let taxIEPS = 0;
                let adjustedAmount = 0;
                if (this.multpleClaims == true) {
                    let firstRecord = [];
                    var setclaimNumber;
                    var recordIDMX;
                    var claimNumberList = [];
                    console.log("selectedNodes",  selectedNodes)
                    for (var i = 0; i < selectedNodes.length; i++) {
                        this.selecetedClaims.push(selectedNodes[i].data.RECORD_ID);
                        if (this.auditTypeID === 'Scan Allowances') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.AMT_DUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA_DUE);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS_DUE);
                        } else if (this.auditTypeID === 'New Stores' || this.auditTypeID === 'Volume Allowances') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.AMT_DUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS);
                        } else if (this.auditTypeID === 'UN-DEDUCTED COOPS') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.BASE_AMT_DUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA_DUE);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS_DUE);
                        } else if (this.auditTypeID === 'Advertising') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.AMTDUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA_AMOUNT);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS_AMOUNT);
                        } else if (this.auditTypeID === 'MISC') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.PENDING_DUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA_DUE);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS_DUE);
                        } else if (this.auditTypeID === 'Fill Rate') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.TOTAL_PENDING);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA_DUE);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS_DUE);
                        }
                        else if (this.auditTypeID === 'MSI') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.AMT_DUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA);
                            taxIEPS = 0;
                        }
                        else if (this.auditTypeID === 'Low Price') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.INV_COST_DUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.INV_COST_DUE);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS_COST_DUE);
                        }
                        else if (this.auditTypeID === 'Shortages') {
                            // debugger
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.SHORT_DUE);
                            taxIVA = taxIVA + Number(selectedNodes[i].data.IVA_SHORT_DUE);
                            taxIEPS = taxIEPS + Number(selectedNodes[i].data.IEPS_SHORT_DUE);
                        }
                        else if (this.auditTypeID === 'Markdown') {
                        //  Formula for Markdown
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.DIFFERENCE);
                            taxIEPS = taxIEPS + (Number(selectedNodes[i].data.DIFFERENCE) * Number(selectedNodes[i].data.IEPS));
                            taxIVA = taxIVA + (( Number(selectedNodes[i].data.DIFFERENCE) + taxIEPS) *  Number(selectedNodes[i].data.VAT));
                            adjustedAmount = originalCLaimAmount + taxIEPS + taxIVA;
                            console.log(originalCLaimAmount)
                            console.log(taxIEPS)
                            console.log(taxIVA)
                            console.log(adjustedAmount)
                        }

                        claimNumberList.push(selectedNodes[i].data.Claim_Number);
                        firstRecord.push(selectedNodes[i].data.RECORD_ID);
                    }
                    var notNullClaimNumbers = claimNumberList.filter((s) => s == null);
                    if (notNullClaimNumbers.length < 1) {
                        var notNullClaimNumbers = claimNumberList.filter((s) => s != null);
                        const unique = [...new Set(notNullClaimNumbers)];
                        if (unique.length > 1) {
                            setclaimNumber = null;
                            let data = selectedNodes.map((node) => node.data);
                            recordIDMX = data[0].RECORD_ID;
                        } else {
                            let data = selectedNodes.map((node) => node.data);
                            recordIDMX = data[0].RECORD_ID;
                            setclaimNumber = unique[0];
                        }
                    } else {
                        setclaimNumber = null;
                        let data = selectedNodes.map((node) => node.data);
                        recordIDMX = data[0].RECORD_ID;
                    }

                    let data = selectedNodes.map((node) => node.data);
                    let recordID = data[0].RECORD_ID;

                    // this.apiservice.setSession('recordIDs', this.selecetedClaims);
                    this.apiservice.setSession('recordIDs', this.selecetedClaims);
                    this.apiservice.setSession('originalCLaimAmountMX', originalCLaimAmount);
                    this.apiservice.setSession('taxIVA', taxIVA);
                    this.apiservice.setSession('taxIEPS', taxIEPS);
                    this.apiservice.setSession('adjustedAmount', adjustedAmount);
                    this.apiservice.setSession('claimNumber', setclaimNumber);

                    if (setclaimNumber == null && selectedNodes.length > 1) {
                        if (confirm('You have selected an already created claim. \r\n \r\n Are you sure you want to "OVERWRITE" an already existing claim?')) {
                            this.router.navigate(['/pages/create-claim/' + this.clCode + '/' + this.auditTypeID + '/' + recordID], recordID);
                        }
                    } else {
                        this.router.navigate(['/pages/create-claim/' + this.clCode + '/' + this.auditTypeID + '/' + recordID], recordID);
                    }
                } else {
                    alert('Please select same Vendor number and SAP Vendor');
                }
            } else {
                alert('Please select the row');
            }
        } else {
            if (selectedNodes.length != 0) {
                for (var i = 0; i < selectedNodes.length; i++) {
                    let firstValue = selectedNodes[0].data.RUT;

                    let firstValueAllowType = selectedNodes[0].data.ALLOW_TYPE;

                    if (this.auditTypeID == 'NEW-STORE') {
                        if (firstValue == selectedNodes[i].data.RUT) {
                            this.multpleClaims = true;
                        } else {
                            this.multpleClaims = false;
                        }
                    } else {
                        if (firstValue == selectedNodes[i].data.RUT && firstValueAllowType.toUpperCase() == selectedNodes[i].data.ALLOW_TYPE.toUpperCase()) {
                            this.multpleClaims = true;
                        } else {
                            this.multpleClaims = false;
                        }
                    }
                }
                var originalCLaimAmount = 0;
                if (this.multpleClaims == true) {
                    let firstRecord = [];
                    var setclaimNumber;
                    var recordID;
                    var claimNumberList = [];
                    for (var i = 0; i < selectedNodes.length; i++) {
                        this.selecetedClaims.push(selectedNodes[i].data.RECORD_ID);
                        if (this.auditTypeID === 'NEW-STORE' ) {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.AMOUNT_DUE);
                        } else if (this.auditTypeID === 'UNDED-COOPS') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.AMOUNT);
                        } else if (this.auditTypeID === 'VOLUMES') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.DEBITED_AMOUNT);
                        } else if (this.auditTypeID == 'MISC') {
                            originalCLaimAmount = originalCLaimAmount + Number(selectedNodes[i].data.PENDING_DUE);
                        }
                        claimNumberList.push(selectedNodes[i].data.APP_CLAIM_NUMBER);
                        firstRecord.push(selectedNodes[i].data.RECORD_ID);
                    }
                    var notNullClaimNumbers = claimNumberList.filter((s) => s == null);
                    if (notNullClaimNumbers.length < 1) {
                        var notNullClaimNumbers = claimNumberList.filter((s) => s != null);
                        const unique = [...new Set(notNullClaimNumbers)];
                        if (unique.length > 1) {
                            setclaimNumber = null;
                            let data = selectedNodes.map((node) => node.data);
                            recordID = data[0].RECORD_ID;
                        } else {
                            let data = selectedNodes.map((node) => node.data);
                            recordID = data[0].RECORD_ID;
                            setclaimNumber = unique[0];
                        }
                    } else {
                        setclaimNumber = null;
                        let data = selectedNodes.map((node) => node.data);
                        recordID = data[0].RECORD_ID;
                    }

                    this.apiservice.setSession('recordIDs', this.selecetedClaims);
                    this.apiservice.setSession('originalCLaimAmount', originalCLaimAmount);
                    this.apiservice.setSession('claimNumber', setclaimNumber);

                    if (setclaimNumber == null && selectedNodes.length > 1) {
                        if (confirm('You have selected an already created claim. \r\n \r\n Are you sure you want to "OVERWRITE" an already existing claim?')) {
                            this.router.navigate(['/pages/create-claim/' + this.clCode + '/' + this.auditTypeID + '/' + recordID], recordID);
                        }
                    } else {
                        this.router.navigate(['/pages/create-claim/' + this.clCode + '/' + this.auditTypeID + '/' + recordID], recordID);
                    }
                } else {
                    alert('Please select same RUT number and Allow Type');
                }
            } else {
                alert('Please select the row');
            }
        }
    }

    checkHistory() {
        let selectedNodes = this.gridApi.getSelectedNodes();

        if (selectedNodes.length != 0) {
            if (selectedNodes.length == 1) {
                let recordID = selectedNodes[0].data.RECORD_ID;

                this.router.navigate(['/pages/assign-history/' + this.clCode + '/' + this.auditTypeID + '/' + recordID]);
            } else {
                alert('Please select only one row');
            }
        } else {
            alert('Please select the row');
        }
    }

    loadAuditTypeValues(CLCode) {
        this.apiservice.getAuditType(CLCode).subscribe((response) => {
            this.auditDropDown = response;
            this.selecteddata = this.auditTypeID;
        });
    }

    selectedValue(auditTypeId: any) {
        this.showLoader = true;
        this.pageNumber = -1;
        this.numberOfPage = 0;
        this.pageNumber++;
        this.numberOfPage++;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
        this.router.navigate(['managePR/' + this.clCode + '/' + auditTypeId]);
        this.Refresh(auditTypeId);
    }

    Refresh(auditTypeId) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['managePR/' + this.clCode + '/' + auditTypeId]);
    }

    refreshPage() {
        this.showLoader = true;
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['managePR/' + this.clCode + '/' + this.auditTypeID]);
    }

    printSortStateToConsole($event) {
        if (this.advanceFilterEnabled) {
            this.keyOrder = this.keyOrder === 1 ? 0 : 1;
            this.apiservice.qrPageFiltersMX(this.country, this.advanceFilterPayload, this.auditTypeID, this.auditYear, this.pageNumber, this.key, this.keyOrder).subscribe(
                (result) => {
                    this.processTable(result);
                },
                (error) => {
                    this.showLoader = false;
                    this.openSnackBar('There was an error ' + error, 'Ok');
                }
            );
        }
    }

    selectedValueAuditYear(ayditYear: any) {
        this.showLoader = true;
        this.pageNumber = -1;
        this.numberOfPage = 0;
        this.pageNumber++;
        this.numberOfPage++;
        const rowData = [];
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, ayditYear, this.country_cd, this.pageNumber);
    }

    goToPrv() {
        this.showLoader = true;
        this.pageNumber--;
        this.numberOfPage--;
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
    }

    goToNext() {
        this.showLoader = true;
        this.pageNumber++;
        this.numberOfPage++;
        if (this.numberOfPage > 1) {
            this.prvDisabled = false;
        } else {
            this.prvDisabled = true;
        }
        if (this.numberOfPage >= this.totalNumber) {
            this.nextDisabled = true;
        } else {
            this.nextDisabled = false;
        }
        this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
    }

    onPageNumber($event) {
        let pageEntered = $event.target.value;
        if (pageEntered >= 1 && pageEntered <= this.totalNumber) {
            this.showLoader = true;
            if (this.numberOfPage > 1) {
                this.prvDisabled = false;
            } else {
                this.prvDisabled = true;
            }
            if (this.numberOfPage >= this.totalNumber) {
                this.nextDisabled = true;
            } else {
                this.nextDisabled = false;
            }
            this.pageNumber = pageEntered;
            this.updateDataTable(this.auditTypeID, this.auditYear, this.country_cd, this.pageNumber);
        } else {
            $event.preventDefault();
            if (pageEntered < 1 || pageEntered > this.totalNumber) {
                this.openSnackBar('Please enter the correct page number', 'Ok');
            }
        }
    }

    populateGrid(result) {
        const rowData = [];
        _.each(result, (v) => {
            rowData.push(this.convertObject(v));
        });
        this.showLoader = false;
        this.rowData = rowData;

        this.gridApi.setRowData(this.rowData);
        this.gridApi.hideOverlay();
        const columnDf = this.generateColumns(this.rowData);
        columnDf[0] = {
            ...columnDf[0],
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        };
        this.columnDefs = columnDf;
        this.showLoader = false;
    }


    advanceFilters() {
        const dialogRef = this.dialog.open(CommaFilterComponent, {
            width: '200',
            hasBackdrop: true,
            data: {
                previousPayload: this.advanceFilterPayload,
                columnDets: this.columnDefs,
                auditYear: this.auditYear,
                id: this.auditTypeID,
                country: this.country_cd
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.from === 'submit') {
                this.advanceFilterPayload = result.payload;
                this.advanceFilterEnabled = true;
                this.processTable(result.data);
            } else {
                this.advanceFilterEnabled = false;
            }
        });
    }

    clearFilters() {
        this.advanceFilterEnabled = false;
        this.Refresh(this.auditTypeID);
    }

    onRowSelected(event: RowSelectedEvent) { }

    onBtnExport(count?) {
        if (this.auditTypeID === 'New Stores' || this.auditTypeID === 'Low Price' || this.auditTypeID === 'Shortages' || this.auditTypeID ===  'UN-DEDUCTED COOPS') {
            let num;
            let payload;
            if (this.advanceFilterEnabled) {
                payload = this.advanceFilterPayload;
            } else {
                payload = {
                    result: []
                };
            }
            if (count == null || count == '' || count == undefined) {
                num = this.pageNumber + 1;
            } else {
                num = count + 1;
            }

            let pageNo = num - 1;
            this.showLoader = true;
            let key = "RECORD_ID";
            this.apiservice.qrPageExport(this.auditTypeID, this.auditYear, pageNo, this.country_cd, payload, this.translate.currentLang, key, this.keyOrder).subscribe((response) => {
                let downloadFIle = response;
                this.downloadFile(downloadFIle, num);
                this.showLoader = false;
            });
        } else {
            this.gridApi.exportDataAsCsv();
        }
    }

    // downloadFile(data: any, count) {
    //     let title = 'my file';
    //     let blob = new Blob([data], {
    //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    //     });
    //     let filename = this.auditTypeID + '_' + count + '.xlsx';
    //     let filecontent = blob;
    //     fs.saveAs(blob, filename);
    //     this.showLoader = false;
    //     var url = window.URL.createObjectURL(fs);
    //     var pwa = window.open(url);
    //     if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //         alert('Please disable your Pop-up blocker and try again.');
    //     }
    // }

    downloadFile(data: any, count: number) {
        
        const title = 'my file';
        const filename = `${this.auditTypeID}_${count}.xlsx`;
    
        
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
    
       
        const url = window.URL.createObjectURL(blob);
    
        
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
    
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 100);
    
        
        this.showLoader = false;
    }
    
    

    duplicateData() {
        let selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes.length != 0) {
            if (selectedNodes.length == 1) {
                let selectedData = selectedNodes.map((node) => node.data);

                var dataTosend = {
                    data: selectedData,
                    id: this.auditTypeID,
                    countryCode: this.clCode
                };
                const dialogRef = this.dialog.open(DuplicateDataComponent, {
                    hasBackdrop: true,
                    data: { requestId: dataTosend }
                });
                dialogRef.afterClosed().subscribe((result) => { });
            } else {
                alert('Please select only one row');
            }
        } else {
            alert('Please select the row');
        }
    }

    editData() {
        let selectedNodes = this.gridApi.getSelectedNodes();

        if (selectedNodes.length != 0) {
            let selectedData = selectedNodes.map((node) => node.data);
            var dataTosend = {
                data: selectedData,
                id: this.auditTypeID,
                countryCode: this.clCode
            };

            const dialogRef = this.dialog.open(EditDataComponent, {
                width: '200',

                hasBackdrop: true,

                data: { requestId: dataTosend }
            });

            dialogRef.afterClosed().subscribe((result) => { });
        } else {
            alert('Please select the row');
        }
    }

    miscellaneous() {
        var dataTosend = { id: this.auditTypeID, countryCode: this.clCode };
        const dialogRef = this.dialog.open(MiscellaneousPRComponent, {
            width: '200',
            hasBackdrop: true,
            data: { requestId: dataTosend }
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }

    changeStatus() {
        let selectedNodes = this.gridApi.getSelectedNodes();

        if (selectedNodes.length != 0) {
            let selectedData = selectedNodes.map((node) => node.data);
            var dataTosend = {
                data: selectedData,
                id: this.auditTypeID,
                countryCode: this.clCode
            };
            const dialogRef = this.dialog.open(ChangeStatusComponent, {
                hasBackdrop: true,
                data: { requestId: dataTosend }
            });
            dialogRef.afterClosed().subscribe((result) => {
                //  this.refreshPage()
            });
        } else {
            alert('Please select the row');
        }
    }

    getTranslation(str) {
        const currentLang = this.translate.currentLang;
        // const returnValue = this.translate.translations[currentLang][str];
        let returnValue;
        this.translate.get(str).subscribe((data) => {
            returnValue = data;
        });

        if (returnValue === undefined) {
            return this.translate.translations.en[str];
        } else {
            return returnValue;
        }
    }

    updateDataTable(auditTypeID, auditYear, country_cd, pageNumber) {
        if (this.advanceFilterEnabled) {
            this.apiservice.qrPageFiltersMX(this.country, this.advanceFilterPayload, this.auditTypeID, this.auditYear, this.pageNumber, this.key, this.keyOrder).subscribe(
                (response) => {
                    let result = response;
                    this.processTable(result);
                },
                (error) => {
                    this.showLoader = false;
                    this.openSnackBar('There was an error ' + error, 'Ok');
                }
            );
        } else {
            this.apiservice.getAuditTableDataByYear(auditTypeID, auditYear, country_cd, pageNumber).subscribe(
                (result: any) => {
                    this.processTable(result);
                },
                (error) => {
                    this.showLoader = false;
                    this.openSnackBar('There was an error ' + error, 'Ok');
                }
            );
        }
    }

    processTable(result) {
        console.log(result)
        this.exportBtnList = [];
        this.TotalRecords = result.count[0];
        this.totalNumber = 0;
        this.totalNumber = Math.ceil(result.count[0] / result.data.length);
        if (isNaN(this.totalNumber)) {
            this.totalNumber = 0;
            this.showPagination = false;
        } else {
            this.showPagination = true;
        }
        this.totalNumberFilter = Math.floor(this.TotalRecords / 150000);
        for (let i = 0; i <= this.totalNumberFilter; i++) {
            this.exportBtnList.push(i);
        }
        this.populateGrid(result.data);
        if (this.totalNumber <= 1) {
            this.nextDisabled = true;
            this.inputDisabled = true;
        } else if (this.totalNumber > 1) {
            this.nextDisabled = false;
            this.inputDisabled = false;
        }
        this.showLoader = false;
    }

   

    openDialog() {
        let selectedNodes = this.gridApi.getSelectedNodes();
        if (selectedNodes.length === 0) {
          alert('Please select a row');
          return;
        }
    
        this.showLoader = true;
        let selectedData = selectedNodes.map((node) => node.data);
    
        let requestPayload = selectedData.map((v) => ({
          AUDIT_YEAR: v.AUDIT_YEAR,
          VENDOR_NBR: v.VENDOR_NBR,
          CATEGORY: v.CAT,
          DEPARTMENT: v.DEPT,
          CAT: v.CAT,
          ITEM_NUM: v.ITEM_NUM,
          BANNER_DESC: v.BANNER_DESC,
        }));
    
        console.log('Request Payload:', requestPayload);
    
        this.apiservice.getMarkdownChartData(requestPayload).subscribe(
          (response) => {
            console.log('API Response:', response);
    
            if (response && response.length) {
              const categories = response.map(item => item.MONTH_DESC);
              const costData = response.map(item => parseFloat(item.UNIT_COST));
              const retailData = response.map(item => parseFloat(item.UNIT_RETAIL));
              const avgSalesPriceData = response.map(item => parseFloat(item.AVG_SALE_PRICE));
    
              console.log('Categories:', categories);
              console.log('Cost Data:', costData);
              console.log('Retail Data:', retailData);
              console.log('Avg Sales Price Data:', avgSalesPriceData);
    
              this.chart = {
                chart: {
                  type: 'line'
                },
                title: {
                  text: ''
                },
                xAxis: {
                  categories: categories
                },
                yAxis: {
                  title: {
                    text: 'Cost - Retail'
                  }
                },
                series: [
                  {
                    name: 'Unit Cost',
                    data: costData,
                    type: 'line',
                    color: 'black'
                  },
                  {
                    name: 'Average Sales Price',
                    data: avgSalesPriceData,
                    type: 'line',
                    color: 'orange'
                  },
                  {
                    name: 'Unit Retail',
                    data: retailData,
                    type: 'line',
                    color: 'blue'
                  }
                ]
              };
    
              const tableData = _.uniqBy(response, 'ITEM_NUM').map(item => ({
                VENDOR_NBR: item.VENDOR_NBR,
                VNDR_NAME: item.VNDR_NAME,
                ITEM_NUM: item.ITEM_NUM,
                ITEM1_DESC: item.ITEM1_DESC,
                ITEM2_DESC: item.ITEM2_DESC,
                DEPARTMENT: item.DEPARTMENT,
                VENDOR_STOCK_ID: item.VENDOR_STOCK_ID,
                UPC_NBR: item.UPC_NBR,
                FINELINE_NBR: item.FINELINE_NBR,
                ITEM_STATUS_CODE: item.ITEM_STATUS_CODE,
                USHLFLBL1_COLR_DESC: item.SHLFLBL1_COLR_DESC,
                SHLFLBL2_SIZE_DESC: item.SHLFLBL2_SIZE_DESC

              }));
    
              console.log('Table Data:', tableData);
    
              
              const dialogRef = this.dialog.open(ChartComponent, {
                width: '1200px', 
                hasBackdrop: true,
                data: {
                  chartData: this.chart,
                  tableData: tableData
                }
              });
    
              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
              });
    
              this.showLoader = false;
            } else {
              this.showLoader = false;
              alert('No data available');
            }
          },
          (error) => {
            this.showLoader = false;
            console.error('API Error:', error);
            alert('An error occurred while fetching data');
          }
        );
      }
    

    

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top'
        });
    }
    // onFirstDataRendered(params: FirstDataRenderedEvent) {
    //   params.api.sizeColumnsToFit();
    // }
}

// var filterParams = {
//   suppressAndOrCondition: false,
//   filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual',
//     'greaterThan', 'greaterThanOrEqual'],
//   buttons: ['reset', 'apply'],
//   closeOnApply: true,
//   defaultJoinOperator: 'OR',
// };
function monthToNumber(date: any) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    const yearNumber = Number.parseInt(date.substring(6, 10));
    const monthNumber = Number.parseInt(date.substring(3, 5));
    const dayNumber = Number.parseInt(date.substring(0, 2));
    const result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function isFirstColumn(params): boolean {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
}

function cvrtStringToNob(data) {
    if (data != '' && data != null && data != 'NaN') {
        if (!isNaN(data) && typeof data == 'number') {
            // check if it is integer
            if (Number.isInteger(data)) {
                return data;
            } else {
                return data;
            }
        } else {
            const v = parseFloat(data.replace(/,/g, ''));
            return Number(v);
        }
    } else {
        return null;
    }
}

function convertIntoDate(dateValue) {
    if (dateValue != null && dateValue != undefined) {
        const dateArray = dateValue.split('-');
        const dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
        const dateObj = new Date(dateString);
        return dateObj;
    } else {
        return null;
    }
}
