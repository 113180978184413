import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TransferItem } from 'ng-zorro-antd/transfer';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { apiService } from 'src/app/_services/app.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
// import { MatTableDataSource, MatPaginator } from '@angular/material';
export interface DialogData {
    data: any;
}

@Component({
    selector: 'app-pivot-table',
    templateUrl: './pivot-table.component.html',
    styleUrls: ['./pivot-table.component.css']
})
export class PivotTableComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<PivotTableComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar, public translate: TranslateService, private apiservice: apiService) {}
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    list: TransferItem[] = [];
    preProcessedData = [];
    auditYearArray = [];
    auditYears = ['2018','2019','2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030'];
    showLoader = false;
    showSearch = true;
    showTable = false;
    payload = {
        result: [],
        count: [],
        sum: [],
        avg: [],
        min: [],
        max: [],
        groupby: []
    };

    ngOnInit(): void {
        this.setAuditYearFromAdvanceFilter();   
        this.getColumnSchemas(this.data.id, this.data.country);
        for (let i = 0; i < this.data.columnDets.length; i++) {
            this.list.push({
                key: this.data.columnDets[i].field,
                title: this.data.columnDets[i].headerName
            });
        }
        this.processPreviousPayload();
    }

    getColumnSchemas(tableName,country) {
        this.showLoader = true;
        this.apiservice.getColumnSchemas(tableName,country).subscribe(
            (response) => {
                this.list.forEach((element) => {
                    response.forEach((value) => {
                        if (element.key == value.name) {
                            element['type'] = value.type;
                        }
                    });
                });
                this.showLoader = false;
                this.showTable = true;
            },
            (error) => {
                this.showLoader = false;
                this.showTable = false;
                this.openSnackBar('There was an error ' + error, 'Ok');
            }
        );
    }

    convertItems(items: TransferItem[]): TransferItem[] {
        return items.filter((i) => !i.hide);
    }

    select(ret: {}): void {}

    change(ret: {}): void {
        // Assigining
        if (ret['from'] === 'left' && ret['to'] === 'right') {
            ret['list'].forEach((val) => {
                this.preProcessedData.push(val);
            });
        }

        if (ret['from'] === 'right' && ret['to'] === 'left') {
            ret['list'].forEach((val) => {
                this.preProcessedData = this.preProcessedData.filter((data) => !(val.key === data.key));
            });

            /* This function is used to remove the data from the payload if data is moved from right to left
             */
            for (let arr of Object.entries(this.payload)) {
                if (arr[1].length > 0) {
                    for (let i = 0; i < arr[1].length; i++) {
                        if (ret['list'].length > 0) {
                            for (let j = 0; j < ret['list'].length; j++) {
                                if (ret['list'][j].key == arr[1][i].name) {
                                    arr[1].splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        this.processPreviousPayload();
        this.processAuditYear(this.auditYearArray);
    }

    changeAggregatoryFunction(functionValue, columnData) {
        /* This function is used to assign the agg function to the column, the logic below is to check if a column is 
        already assigned to a function then removing that and assigining it to a new function.
        eg: If VendorNbr is assigned to GroupBy and changed to Count - It's removed from the GroupBy array and assigned to Count*/
        for (let arr of Object.entries(this.payload)) {
            if (arr[1].length > 0) {
                for (let i = 0; i < arr[1].length; i++) {
                    if (columnData.key == arr[1][i].name) {
                        arr[1].splice(i, 1);
                    }
                }
            }
        }
        // Assigning the function to the column.
        this.payload[functionValue].push({
            name: columnData.key
        });
        this.processPreviousPayload();
        this.processAuditYear(this.auditYearArray);
    }

    changeAuditYear(auditYears, columnData) {
        console.log(auditYears, columnData)
        this.payload.result = [];
        this.processPreviousPayload();
        this.auditYearArray = auditYears;
        if (auditYears.length>0) {
            let quotedAndCommaSeparated = auditYears.map(d => `'${d}'`).join(',');
            if (this.data.previousPayload != undefined && this.data.previousPayload != null) {
                let payload_result = this.payload.result;
                payload_result.forEach(obj => {
                    if (obj.name == 'AUDIT_YEAR' || obj.name == 'AuditYear' || obj.name == 'FYR') {
                        obj.value = quotedAndCommaSeparated;
                    }
                })
            } else {
                this.payload.result.push({
                    'name':columnData.key,
                    'value':quotedAndCommaSeparated
                })
            }
        }
    }

   
    

    

    

   

    processAuditYear(auditYearArray) {
        if (auditYearArray.length > 0) {
            let quotedAndCommaSeparated = auditYearArray.map(year => `'${year}'`).join(',');
    
            if (this.data.previousPayload) {
                
                let existingAuditYear = this.payload.result.find(obj => 
                    obj.name === 'AuditYear' || obj.name === 'AUDIT_YEAR' || obj.name === 'FYR'
                );
    
                if (existingAuditYear) {
                    
                    existingAuditYear.value += ',' + quotedAndCommaSeparated;
                } else {
                    
                    this.payload.result.push({
                        'name': 'AUDIT_YEAR', 
                        'value': quotedAndCommaSeparated
                    });
                }
            } else {
                
                this.payload.result.push({
                    'name': 'AUDIT_YEAR', 
                    'value': quotedAndCommaSeparated
                });
            }
        }
    }
    
    

    
    
    
    
    

    setAuditYearFromAdvanceFilter() {
        if (this.data.previousPayload != undefined && this.data.previousPayload != null) {
            let previousPayload = this.data.previousPayload;
            this.auditYearArray = [];
            for (const key in previousPayload) {
                if (previousPayload[key] !== null) {
                    if (key == 'AUDIT_YEAR' || key == 'AuditYear' || key == 'FYR') {
                        this.auditYearArray.push(previousPayload[key])
                    }
                }
            }
        }
    }


    

    processPreviousPayload() {
        this.payload.result = [];
        let pr_dt_st, pr_dt_ed;
        if (this.data.previousPayload != undefined && this.data.previousPayload != null) {
            let previousPayload = this.data.previousPayload;    
            for (const key in previousPayload) {
                if (this.data.id != 'PPA_FILL_RATE_DWH' && key == 'INCLUDE') {
                    previousPayload['INCLUDE'] = null;
                }
                if (previousPayload[key] !== null) {
                    if ( (this.data.id == 'PPA_DSD_DWH' || this.data.id == 'PPA_STORE_SHIPS_DWH') && key == 'VENDOR_NUMBER') {
                        this.payload.result.push({
                            name: 'VEN_NUM',
                            value: `'${previousPayload[key]}'`
                        });
                    } else  if ((this.data.id == 'PPA_DSD_DWH' || this.data.id == 'PPA_STORE_SHIPS_DWH' || this.data.id == 'PPA_VOLUME_ALLOWANCE_DEDUCTION' ) && key == 'ITEM_NO') {
                        this.payload.result.push({
                            name: 'ITEM_NBR',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if ((this.data.id == 'PPA_PO_WM' || this.data.id == 'PPA_PO_SAMS' || this.data.id == 'PPA_VOLUME_ALLOWANCE_DEDUCTION' ) && key == 'VENDOR_NUMBER') {
                        this.payload.result.push({
                            name: 'VENDOR_NBR',
                            value: `'${previousPayload[key]}'`
                        });
                    } else  if (this.data.id == 'PPA_PO_SAMS' && key == 'ITEM_NO') {
                        this.payload.result.push({
                            name: 'ITEM_NUMBER',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_SCAN_REBATE_DEDUCTIONS' && key == 'AGREEMENT_NUMBER') {
                        this.payload.result.push({
                            name: 'ARNUM',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_VOLUME_ALLOWANCE_DEDUCTION' && key == 'DEAL') {
                        this.payload.result.push({
                            name: 'AGREEMENT_NUMBER',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_VOLUME_ALLOWANCE_DEDUCTION' && key == 'SAP_NUMBER') {
                        this.payload.result.push({
                            name: 'SAP_VENDOR',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_VOLUME_ALLOWANCE_DEDUCTION' && key == 'DEPT_NO') {
                        this.payload.result.push({
                            name: 'DEAL_DEPTCAT',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_SCAN_REBATE_DEDUCTIONS' && key == 'VENDOR_NUMBER') {
                        this.payload.result.push({
                            name: 'VENDOR_HOST',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_SCAN_REBATE_DEDUCTIONS' && key == 'Item') {
                        this.payload.result.push({
                            name: 'IPITM',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_POS_ECOMMERCE' && key == 'SUPLR_NBR') {
                        this.payload.result.push({
                            name: 'SUPPLIER_NUM',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_POS_ECOMMERCE' && key == 'DEPT_NO') {
                        this.payload.result.push({
                            name: 'DEPTO',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_DSD_DWH' && key == 'DEPT_NO') {
                        this.payload.result.push({
                            name: 'DEPT_NBR',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_POS_ECOMMERCE' && key == 'ITEM_NO') {
                        this.payload.result.push({
                            name: 'ITEM',
                            value: `'${previousPayload[key]}'`
                        });
                    } else if (this.data.id == 'PPA_SCAN_REBATE_DEDUCTIONS' && key == 'PRICE_DATE_START') {
                        pr_dt_st = previousPayload[key];
                    } else if (this.data.id == 'PPA_SCAN_REBATE_DEDUCTIONS' && key == 'PRICE_DATE_END') {
                        pr_dt_ed = previousPayload[key];
                        this.payload.result.push({
                            name: 'PRDAT',
                            value: `${pr_dt_st},${pr_dt_ed}`
                        });
                    } else {
                        this.payload.result.push({
                            name: key,
                            value: `'${previousPayload[key]}'`
                        });
                    }
                }
            }
        }
    }

    
    onSubmit() {
        if (this.auditYearArray.length < 1) {
            alert("Please Select the Audit Year");
        } else {
            this.showLoader = true;
            this.apiservice.pivotTable(this.data.id, this.payload, this.data.country).subscribe(
                (response) => {
                    console.log(response)
                    this.showLoader = false;
                    this.dialogRef.close({
                        data: response,
                        payload: this.payload,
                        from: 'submit'
                    });
                },
                (error) => {
                    this.showLoader = false;
                    this.openSnackBar('There was an error ' + error, 'Ok');
                    this.dialogRef.close({
                        data: [],
                        payload: this.payload,
                        from: 'submit'
                    });
                }
            );
        }
    }

    onCancel() {
        this.dialogRef.close({
            data: [],
            payload: this.payload,
            from: 'cancel'
        });
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000,
            verticalPosition: this.verticalPosition
        });
    }
}
