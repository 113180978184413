import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EditDetails } from 'src/app/_models/editDetails';
import { apiService } from 'src/app/_services/app.service';

export interface DialogData {
    requestId: any;
}

@Component({
    selector: 'app-filter-bulk-upload',
    templateUrl: './filter-bulk-upload.component.html',
    styleUrls: ['./filter-bulk-upload.component.css']
})
export class FilterBulkUploadComponent implements OnInit {
    auditorList: any;
    uploadeData: any;
    bulkUpload: any;
    pageID: any;
    ccCode: any;
    currentValue: any;
    data: any;
    multiDataSend = [];
    auditYear = '';
    username: any;
    showLoader = false;
    filteredPayload: any;
    sendEditedData: any;

    constructor(public dialogRef: MatDialogRef<FilterBulkUploadComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, public router: Router, public activatedRoute: ActivatedRoute, public translate: TranslateService, private apiservice: apiService) {
        this.bulkUpload = new EditDetails();

        this.bulkUpload.Include = '';
    }

    addForm: FormGroup = new FormGroup({
        Include: new FormControl('', Validators.required)
    });

    ngOnInit() {
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;
        this.uploadeData = this.datas.requestId.data;
        this.pageID = this.datas.requestId.id;
        this.ccCode = this.datas.requestId.countryCode;
        this.auditYear = this.datas.requestId.auditYear;
        //  this.currentValue = this.uploadeData[0].Include;
        this.filteredPayload = this.datas.requestId.filteredData;
        console.log(" this.filteredPayload", this.filteredPayload)
        this.SetLang();
    }



    onSubmit() {
        this.showLoader = true;


        if (this.ccCode == 'CL') {
            this.sendEditedData = {
                Include: this.bulkUpload.Include,
                LastupdateBy: this.username,
                AllowType: this.filteredPayload.AllowType,
                AuditYear: this.filteredPayload.AuditYear,
                Clase: this.filteredPayload.Clase,
                DeptNbr: this.filteredPayload.DeptNbr,
                Format: this.filteredPayload.Format,
                ItemNbr: this.filteredPayload.ItemNbr,
                Mes: this.filteredPayload.Mes,
                PeriodEnd: this.filteredPayload.PeriodEnd,
                PeriodStart: this.filteredPayload.PeriodStart,
                PurchaseDateEnd: this.filteredPayload.PurchaseDateEnd,
                PurchaseDateStart: this.filteredPayload.PurchaseDateStart,
                RUT: this.filteredPayload.RUT,
                SalesDateEnd: this.filteredPayload.SalesDateEnd,
                SalesDateStart: this.filteredPayload.SalesDateStart,
                StoreNumber: this.filteredPayload.StoreNumber,
                VendorNbr: this.filteredPayload.VendorNbr,

            };

        }
        else {
            this.sendEditedData = {
                AUDIT_YEAR: this.filteredPayload.AUDIT_YEAR,
                VENDOR_NUMBER: this.filteredPayload.VENDOR_NUMBER,
                AGREEMENT_NUMBER: this.filteredPayload.AGREEMENT_NUMBER,
                UPC_NUMBER: this.filteredPayload.UPC_NUMBER,
                LEGACY_ITEM_NUMBER: this.filteredPayload.LEGACY_ITEM_NUMBER,
                ITEM_NO: this.filteredPayload.ITEM_NO,
                DEPT_NO: this.filteredPayload.DEPT_NO,
                SalesDateStart: this.filteredPayload.SalesDateStart,
                SalesDateEnd: this.filteredPayload.SalesDateEnd,
                Include: this.bulkUpload.Include,
                LastupdateBy: this.username,
                FORMAT: this.filteredPayload.FORMAT,
                //  MIN_DATE: this.filteredPayload.MIN_DATE,
                PO_SOURCE: this.filteredPayload.PO_SOURCE,
                SAP_NUMBER: this.filteredPayload.SAP_NUMBER,
                SEQ_NO: this.filteredPayload.SEQ_NO,
                STORE_NUM: this.filteredPayload.STORE_NUM,
                // PRICE_DATE: this.filteredPayload.PRICE_DATE,
                FINELINE_NBR: this.filteredPayload.FINELINE_NBR,
                BANNER_CODE: this.filteredPayload.BANNER_CODE,
                CLUB_NBR: this.filteredPayload.CLUB_NBR,
                PROC_DATE_START: this.filteredPayload.PROC_DATE_START,
                PROC_DATE_END: this.filteredPayload.PROC_DATE_END,
                CAT: this.filteredPayload.CAT,
                PO_NUM: this.filteredPayload.PO_NUM,
                ASSIGNMENT: this.filteredPayload.PO_NUM,
                INVOICE_NBR: this.filteredPayload.PO_NUM,
                // RECV_DATE: this.filteredPayload.VendorNbr,
                RECV_DATE_START: this.filteredPayload.RECV_DATE_START,
                RECV_DATE_END: this.filteredPayload.RECV_DATE_END,
                PRICE_DATE_START: this.filteredPayload.PRICE_DATE_START,
                PRICE_DATE_END: this.filteredPayload.PRICE_DATE_END,
                MIN_DATE_START: this.filteredPayload.MIN_DATE_START,
                MIN_DATE_END: this.filteredPayload.MIN_DATE_END,
                INV_DATE_START: this.filteredPayload.INV_DATE_START,
                INV_DATE_END: this.filteredPayload.INV_DATE_END,
                RCVD_NBR: this.filteredPayload.RCVD_NBR,
                REF_NUM: this.filteredPayload.REF_NUM,
                INCLUDE: this.filteredPayload.INCLUDE,
                PAID_AMOUNT_FROM: this.filteredPayload.PAID_AMOUNT_FROM,
                PAID_AMOUNT_TO: this.filteredPayload.PAID_AMOUNT_TO,
                FYR: this.filteredPayload.FYR,
                SAP_VND: this.filteredPayload.SAP_VND,
                DOC_NBR: this.filteredPayload.DOC_NBR,
                SUPLR_NBR: this.filteredPayload.SUPLR_NBR,
                INV_NUMBER: this.filteredPayload.INV_NUMBER,
                ASSIGN: this.filteredPayload.ASSIGN,
                PO_NUMBER: this.filteredPayload.PO_NUMBER,
                UUID: this.filteredPayload.UUID,
                DATE_START: this.filteredPayload.null,
                DATE_END: this.filteredPayload.null,
                // VENDOR_NBR: this.filteredPayload.VENDOR_NBR,
                GRE_DATE: this.filteredPayload.GRE_DATE,
                DEPARTMENT: this.filteredPayload.DEPARTMENT,
                ITEM_NUM: this.filteredPayload.ITEM_NUM,


            };
        }


        if (confirm(' Do you want to Submit the changes?')) {
            this.apiservice.filterBulkUpdate(this.sendEditedData, this.pageID, this.auditYear, this.ccCode).subscribe(
                (result: any) => {
                    alert('data updated sucessfully');
                    this.showLoader = false;
                    this.dialogRef.close();
                    // this.router.navigate(['/pages/data/' + this.ccCode + '/' + this.pageID]);
                    // this.Refresh();
                },
                (error) => {
                    this.showLoader = false;
                }
            );
        } else {
            this.showLoader = false;
        }
    }

    Refresh() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/pages/data/' + this.ccCode + '/' + this.pageID], { relativeTo: this.activatedRoute });
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }
}
