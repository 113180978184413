import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import _ from 'lodash';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'app-pdf-view',
    templateUrl: './pdf-view.component.html',
    styleUrls: ['./pdf-view.component.scss']
  })
  export class PdfViewComponent implements OnInit {
    domain = environment.ppa;
    fileUrl: SafeResourceUrl; 
    selectedFile: any;
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private sanitizer: DomSanitizer 
    ) {}
  
    ngOnInit(): void {
      if (this.data && this.data.length > 0) {
        this.selectedFile = this.data[0];
        this.onFileSelectChange(this.selectedFile);
      }
    }
  
    
    onFileSelectChange(file: any): void {
      if (file && this.isValidFile(file.file_id)) {
        
        const fileUrl = `${this.domain}/FilePreview/${encodeURIComponent(file.file_id)}`;
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl); 
      } else {
        this.fileUrl = null;
      }
    }
  
    
    private isValidFile(fileId: string): boolean {
      const fileIdPattern = /^[a-zA-Z0-9-_]+$/; 
      return fileIdPattern.test(fileId);
    }
  
    
    sanitizeValue(value: string): string {
      return value.replace(/[&<>"'`=/]/g, function (s) {
        return '&#' + s.charCodeAt(0) + ';';
      });
    }
  }