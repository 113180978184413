import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    private _fistIndexValue: any; // Private variable

    set fistIndexValue(value: any) {
        this._fistIndexValue = value; // Setter to update the value
    }

    get fistIndexValue() {
        return this._fistIndexValue; // Getter to retrieve the value
    }
}