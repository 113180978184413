import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, VERSION } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateClaimPackageComponent } from '../create-claim-package/create-claim-package.component';
import { PdfViewComponent } from '../pdf-view/pdf-view.component';
import { HttpClientModule, HttpRequest, HttpResponse, HttpEventType } from '@angular/common/http';
import { apiService } from 'src/app/_services/app.service';
import _ from 'lodash';

@Component({
    selector: 'app-create-claim',
    templateUrl: './create-package.component.html',
    styleUrls: ['./create-package.component.css']
})
export class CreatePackageComponent implements OnInit {
    claim_number: any;
    cancel: boolean = true;
    showLoader = false;
    firstErrorEnabled = false;
    username: any;
    //file upload
    // percentDone: number;
    // uploadSuccess: boolean;
    availableTemplates = [
        { name: 'mx_volume_cover_letter', selected: false },
        { name: 'mx_volume_approval', selected: false },
        { name: 'mx_volume_summary', selected: false },
        { name: 'mx_volume_purchase', selected: false },
        { name: 'mx_volume_deduction', selected: false }
    ];
    selectedTemplates = [];
    selectedFilenames: string[] = [];
    clCode: string; // Example clCode, update as needed
    auditTypeID: string;



    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private http: HttpClient, public dialogRef: MatDialogRef<CreatePackageComponent>, private apiservice: apiService) {}
    version = VERSION;

    ngOnInit() {
        this.claim_number = this.data.claim_number;
        console.log(' this.claim_number', this.claim_number);
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;
    }

    claimTemplateUpload() {
        if (this.claim_number != null) {
            this.showLoader = true;

            this.apiservice.claimTemplateUploadData(this.claim_number, this.username.replace(/\s/g, ''), this.data.country,).subscribe(
                (response) => {
                    this.getFileDetails(this.claim_number);

                    this.showLoader = false;
                },
                (error) => {
                    this.showLoader = false;

                    this.firstErrorEnabled = true;
                    this.createNew([]);
                }
            );
        } else {
            alert('Please select claim number');
            // this.createNew([])
        }
    }
    getFileDetails(id) {
        this.showLoader = true;

        this.apiservice.getFileDetails(id).subscribe(
            (response) => {
                this.createNew(response);

                this.showLoader = false;
            },
            (error) => {
                this.showLoader = false;

                if (!this.firstErrorEnabled) {
                    alert('Data Not present in a system');
                }
            }
        );
    }

    createNew(fileDetails) {
        this.showLoader = false;
        const dialogRef = this.dialog.open(CreateClaimPackageComponent, {
            hasBackdrop: true,
            data: {
                fileDetail: fileDetails,
                claim_data: this.data.claim_info,
                claim_number: this.data.claim_number,
                creationDate: this.data.claimCreationDate,
                country: this.data.country
            },
            // height: '400px',
            width: '700px'
        });
        dialogRef.afterClosed().subscribe((result) => {});
    }

    viewPdf() {
        if (this.claim_number != null && this.claim_number != undefined) {
            if (this.data.fileData.length > 0) {
                const imageFile = [];
                let dialogRef = this.dialog.open(PdfViewComponent, {
                    data: this.data.fileData
                });
            } else {
                alert('No File Available!');
            }
        } else {
            alert('Please select claim number');
        }
    }
}

