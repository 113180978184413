import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { apiService } from 'src/app/_services/app.service';
import { EditDetails } from 'src/app/_models/editDetails';
import { formatDate } from '@angular/common';

export interface DialogData {
    requestId: any;
}
@Component({
    selector: 'app-data-filters',
    templateUrl: './data-filters.component.html',
    styleUrls: ['./data-filters.component.css']
})
export class DataFiltersComponent implements OnInit {
    filtersData: any;

    filteredList: any;
    uploadeData: any;
    bulkUpload: any;
    pageID: any;
    ccCode: any;
    currentValue: any;
    data: any;
    multiDataSend = [];
    AuditYear = '';
    username: any;
    showLoader = false;
    sendEditedData: any;
    tableName: any = '';
    storeNbr: boolean = false;
    deptNumber: boolean = false;
    itemNumber: boolean = false;
    allowTypeDropDown: any;
    enddate: any;
    todayDate: Date;
    addFormMx: FormGroup;
    AllDropdowns: any;
    keyName: string = 'AuditYear';
    keyOrder: number = 0;
    countryCode = 'CL';
    FormatList = [];
    privateLabelList = [];
    last;

    mxPointOfSale = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'AGREEMENT_NUMBER', 'UPC_NUMBER', 'LEGACY_ITEM_NUMBER', 'ITEM_NO', 'DEPT_NO', 'SalesDateStart', 'SalesDateEnd','SAP_NUMBER'];
    auditYears = ['2018','2019','2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030'];
    auditYearArray = [];
    ConstionTypes = ['='];


    constructor(
        public dialogRef: MatDialogRef<DataFiltersComponent>,

        @Inject(MAT_DIALOG_DATA) public datas: any,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        private apiservice: apiService
    ) {
        this.bulkUpload = new EditDetails();
        this.bulkUpload.Include = '';

        // FORM FOR MEXICO

        this.addFormMx = new FormGroup({
            AUDIT_YEAR: new FormControl(datas.requestId.auditYear),
            // VENDOR_NUMBER: new FormControl('', Validators.required),
            AGREEMENT_NUMBER: new FormControl(null),
            UPC_NUMBER: new FormControl(null),
            LEGACY_ITEM_NUMBER: new FormControl(null),
            ITEM_NO: new FormControl(null),
            DEPT_NO: new FormControl(null),
            SalesDateStart: new FormControl(null),
            SalesDateEnd: new FormControl(null),
            SAP_NUMBER: new FormControl(null),
            STORE_NUM: new FormControl(null),
            // MIN_DATE: new FormControl(null),
            SEQ_NO: new FormControl(null),
            FORMAT: new FormControl(null),
            PO_SOURCE: new FormControl(null),
            //  PRICE_DATE: new FormControl(null),
            FINELINE_NBR: new FormControl(null),
            BANNER_CODE: new FormControl(null),
            CLUB_NBR: new FormControl(null),
            PROC_DATE_START: new FormControl(null),
            PROC_DATE_END: new FormControl(null),
            CAT: new FormControl(null),
            PO_NUM: new FormControl(null),
            INVOICE_NBR: new FormControl(null),
            ASSIGNMENT: new FormControl(null),
            RECV_DATE_START: new FormControl(null),
            RECV_DATE_END: new FormControl(null),
            PRICE_DATE_START: new FormControl(null),
            PRICE_DATE_END: new FormControl(null),
            MIN_DATE_START: new FormControl(null),
            MIN_DATE_END: new FormControl(null),
            INV_DATE_START: new FormControl(null),
            INV_DATE_END: new FormControl(null),
            RCVD_NBR: new FormControl(null),
            INCLUDE: new FormControl('Y'),
            REF_NUM: new FormControl(null),
            PAID_AMOUNT_FROM: new FormControl(null),
            PAID_AMOUNT_TO: new FormControl(null),
            FYR: new FormControl(null),
            SAP_VND: new FormControl(null),
            DOC_NBR: new FormControl(null),
            SUPLR_NBR: new FormControl(null),
            INV_NUMBER: new FormControl(null),
            ASSIGN: new FormControl(null),
            PO_NUMBER: new FormControl(null),
            UUID: new FormControl(null),
            CATEGORY: new FormControl(null),
            DATE_START: new FormControl(null),
            DATE_END: new FormControl(null),
            VENDOR_NBR: new FormControl(null),
            GRE_DATE: new FormControl(null),
            DEPARTMENT: new FormControl(null),
            ITEM_NUM: new FormControl(null),
            GRE_DATE_START: new FormControl(null),
            GRE_DATE_END: new FormControl(null),
            SAP_VENDOR: new FormControl(null),
            EFFECTIVE_DATE: new FormControl(null),
            END_DATE: new FormControl(null),
            EFFECTIVE_DATE_START: new FormControl(null),
            EFFECTIVE_DATE_END: new FormControl(null),
            END_DATE_START: new FormControl(null),
            END_DATE_END: new FormControl(null),
            
            

        });

        // debugger
        var pageID = window.location.href;
        var last = pageID.substring(pageID.lastIndexOf('/') + 1, pageID.length);
        this.last = pageID.substring(pageID.lastIndexOf('/') + 1, pageID.length);
        // if (last == 'PPA_CL_COOPS_POS_T') {
        //     let rutControl = new FormControl('', Validators.required);
        //     this.addForm.addControl('RUT', rutControl);
        //     let vndrNbrControl = new FormControl('');
        //     this.addForm.addControl('VendorNbr', vndrNbrControl);
        //     this.addForm.get('VendorNbr');
        // }  
        // else {
        //     let vndrNbrControl = new FormControl(null, Validators.required);
        //     this.addForm.addControl('VendorNbr', vndrNbrControl);
        //     let rutControl = new FormControl('');
        //     this.addForm.addControl('RUT', rutControl);
        //     this.addForm.get('VendorNbr');
        // }

        if (last=='PPA_SCAN_REBATE_DEDUCTIONS') {
            let auditYear = new FormControl('', Validators.required);
            this.addFormMx.addControl('AUDIT_YEAR', auditYear);
        }


        if (last == 'PPA_CL_COOPS_GLAS_T' || last == 'PPA_CL_COOPS_VNDR_T') {
            let rutControl = new FormControl(null);
            this.addForm.addControl('RUT', rutControl);
        }
        else {
            let rutControl = new FormControl('', Validators.required);
            this.addForm.addControl('RUT', rutControl);
        }

        // Set Validators in PPA_NEW_STORE_DEDUCTIONS for MX
        if (last == 'PPA_NEW_STORE_DEDUCTIONS') {
            this.addFormMx.get('SAP_NUMBER').setValidators(Validators.required);
            let vendorNumber = new FormControl(null);
            this.addFormMx.addControl('VENDOR_NUMBER', vendorNumber);
            //  this.addFormMx.get('VENDOR_NUMBER').clearValidators();
        }

        if (last == 'Item_Master_Sams' || last === 'Item_Master_WM' || last === 'PPA_AP_HISTORY' || last == "PPA_POS_ECOMMERCE" || last === "PPA_MARKDOWN_DETAIL" || last === "PPA_MARKDOWN_PRICE_HISTORY" ) {
            let vendorNumber = new FormControl(null);
            this.addFormMx.addControl('VENDOR_NUMBER', vendorNumber);
        } else {
            let vendorNumber = new FormControl(null, Validators.required);
            this.addFormMx.addControl('VENDOR_NUMBER', vendorNumber);
        }

        if (last == "PPA_AP_HISTORY" || last == "PPA_POS_ECOMMERCE") {
            this.addFormMx.get('SUPLR_NBR').setValidators(Validators.required);
        }

        if (last == "PPA_MARKDOWN_DETAIL") {
            this.addFormMx.get('VENDOR_NBR').setValidators(Validators.required);
        }
        if (last == "PPA_MARKDOWN_PRICE_HISTORY") {
            this.addFormMx.get('VENDOR_NBR').setValidators(Validators.required);
        }

       

        

    }

    ngOnInit(): void {
        if (this.datas != undefined) {
            console.log("datas", this.datas)
            this.countryCode = this.datas.requestId.countryCode;
            this.filtersData = this.datas.requestId;
            this.tableName = this.filtersData.id;
            // this.pageIDtable = this.apiservice.getSession('countryCode');
            if (this.countryCode === 'CL') {
                this.generatingOldData(this.filtersData);
                this.tablenameConditions(this.tableName);
                this.getAllowTypesDropDown();
                this.addForm.patchValue(this.datas.filterPayload);
                this.formatDropDownValue(this.countryCode);
            } else {
                if (this.tableName == 'PPA_AP_HISTORY') {
                    this.addFormMx.get('FYR').setValue(this.filtersData.auditYear);
                 } 
                //else {
                //     this.addFormMx.get('AUDIT_YEAR').setValue(this.filtersData.auditYear);
                // }
                // if (this.tableName=='PPA_SCAN_REBATE_DEDUCTIONS') {
                //     this.addFormMx.get('AUDIT_YEAR').setValue(null);
                // }
                this.addFormMx.patchValue(this.datas.filterPayload);
            }
        }
        console.log(this.addFormMx)
    }

    unamePattern = '^[0-9,]*$';

    // FORM FOR CHILE

    addForm: FormGroup = new FormGroup({
        AuditYear: new FormControl(null),
        VendorNbr: new FormControl(null),
        PurchaseDateStart: new FormControl(null),
        PurchaseDateEnd: new FormControl(null),
        SalesDateStart: new FormControl(null),
        SalesDateEnd: new FormControl(null),
        Mes: new FormControl(null),
        AllowType: new FormControl(null),
        PeriodStart: new FormControl(null),
        PeriodEnd: new FormControl(null),
        ItemNbr: new FormControl(null),
        DeptNbr: new FormControl(null),
        StoreNumber: new FormControl(null),
        Format: new FormControl(null),
        Clase: new FormControl(null),
        RECV_DATE: new FormControl(null),

    });

    showFormValue(key) {
        const PONT_SALE = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'ITEM_NO', 'DEPT_NO', 'SalesDateStart', 'SalesDateEnd', 'FINELINE_NBR', 'BANNER_CODE'];

        const POSSams = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SalesDateStart', 'SalesDateEnd', 'ITEM_NO', 'DEPT_NO', 'CLUB_NBR'];

        const vol_Ded = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'AGREEMENT_NUMBER', 'ITEM_NO', 'PRICE_DATE_START', 'PRICE_DATE_END'];

        const Iteam_mast = ['ITEM_NO', 'VENDOR_NUMBER', 'UPC_NUMBER'];

        const new_store_inv = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'DEPT_NO'];

        const new_store_ded = ['AUDIT_YEAR', 'SAP_NUMBER', 'VENDOR_NUMBER', 'STORE_NUM', 'DEPT_NO'];

        const new_storeship = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'ITEM_NO', 'STORE_NUM', 'MIN_DATE_START', 'MIN_DATE_END', 'DEPT_NO'];

        const volumeAllDetails = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'AGREEMENT_NUMBER', 'ITEM_NO', 'DEPT_NO', 'SAP_NUMBER', 'SEQ_NO', 'FORMAT', 'PO_SOURCE'];

        const volAllowDeduc = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'AGREEMENT_NUMBER', 'ITEM_NO', 'PRICE_DATE_START', 'PRICE_DATE_END', 'DEPT_NO', 'SEQ_NO'];

        const POVolums = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'PROC_DATE_START', 'PROC_DATE_END', 'ITEM_NO', 'DEPT_NO', 'PO_NUM', 'SEQ_NO', 'FINELINE_NBR'];

        const POVolumsSams = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'PROC_DATE_START', 'PROC_DATE_END', 'ITEM_NO', 'CAT', 'PO_NUM', 'SEQ_NO', 'FINELINE_NBR'];
        const PPAUndeductedCoops = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'AGREEMENT_NUMBER'];
        const ppaFBLN = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'INVOICE_NBR', 'ASSIGNMENT'];
        const ppaFBLNAdvertis = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'INVOICE_NBR', 'ASSIGNMENT', 'DEPT_NO'];
        const ppaFBLNAdvertisDeduction = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER'];
         
        const DSDvolumn = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'ITEM_NO', 'DEPT_NO', 'SEQ_NO', 'INCLUDE', 'FINELINE_NBR', 'RECV_DATE_START', 'RECV_DATE_END'];

        const fillRateDWH = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'FORMAT', 'PO_NUM', 'ITEM_NO', 'DEPT_NO', 'INCLUDE'];
        const msiDWH = ['AUDIT_YEAR', 'VENDOR_NUMBER', 'SAP_NUMBER', 'DEPT_NO', 'FORMAT', 'REF_NUM', 'PAID_AMOUNT_FROM', 'PAID_AMOUNT_TO']

        const gmilDWH = ['VENDOR_NUMBER', 'INVOICE_NBR', 'PO_NUM', 'INV_DATE_START', 'INV_DATE_END', 'DEPT_NO', 'SEQ_NO', 'ITEM_NO', 'SAP_NUMBER', 'UPC', 'RCVD_NBR']
        const ediDWH = ['VENDOR_NUMBER', 'INV_DATE_START', 'INV_DATE_END', 'ITEM_NO', 'PO_NUM', 'INVOICE_NBR']

        const apHistoryDWH = ['FYR', 'SAP_VND', 'DOC_NBR', 'SUPLR_NBR', 'INV_NUMBER', 'ASSIGN', 'PO_NUMBER', 'UUID'];
        const ecommerceDWH = ['AUDIT_YEAR', 'SUPLR_NBR', 'DEPT_NO', 'ITEM_NO', 'CATEGORY', 'DATE_START', 'DATE_END'];
        const markdownDetail = ['VENDOR_NBR', 'GRE_DATE', 'DEPARTMENT', 'ITEM_NUM', 'BANNER_CODE']; 
        const markdownPriceHistory = ['VENDOR_NBR','SAP_NUMBER','SEQ_NO','ITEM_NUM','EFFECTIVE_DATE','END_DATE']



        if (this.tableName === 'PPA_MX_POS_WM') {
            const indexNum = PONT_SALE.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_MX_POS_SAMS') {
            const indexNum = POSSams.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_SCAN_REBATE_DEDUCTIONS') {
            const indexNum = vol_Ded.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'Item_Master_Sams' || this.tableName === 'Item_Master_WM') {
            const indexNum = Iteam_mast.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_NEW_STORE_INVENTORY') {
            const indexNum = new_store_inv.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_NEW_STORE_DEDUCTIONS') {
            const indexNum = new_store_ded.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_STORE_SHIPS_DWH') {
            const indexNum = new_storeship.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_VOLUME_ALLOWANCE_DETAIL') {
            const indexNum = volumeAllDetails.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_VOLUME_ALLOWANCE_DEDUCTION') {
            const indexNum = volAllowDeduc.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_PO_WM') {
            const indexNum = POVolums.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_PO_SAMS') {
            const indexNum = POVolumsSams.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_UNDEDUCTED_COOPS_DWH') {
            const indexNum = PPAUndeductedCoops.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        } else if (this.tableName === 'PPA_FBL5N') {
            const indexNum = ppaFBLN.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_FBL5N_ADVERTISING' || this.tableName === 'PPA_FBL5N_FillRate'
        ) {
            const indexNum = ppaFBLNAdvertis.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_ADVERTISING_DEDUCTIONS') {
            const indexNum = ppaFBLNAdvertisDeduction.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_DSD_DWH') {
            const indexNum = DSDvolumn.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_FILL_RATE_DWH') {
            const indexNum = fillRateDWH.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_MSI_DWH' || this.tableName === 'PPA_MSI_DEDUCTIONS') {
            const indexNum = msiDWH.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_GMIL_DWH') {
            const indexNum = gmilDWH.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_EDI_DWH') {
            const indexNum = ediDWH.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_AP_HISTORY') {
            const indexNum = apHistoryDWH.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }
        else if (this.tableName === 'PPA_POS_ECOMMERCE') {
            const indexNum = ecommerceDWH.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }

        else if (this.tableName === 'PPA_MARKDOWN_DETAIL') {
            const indexNum = markdownDetail.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }

        else if (this.tableName === 'PPA_MARKDOWN_PRICE_HISTORY') {
            const indexNum = markdownPriceHistory.indexOf(key);

            if (indexNum > -1) {
                return true;
            } else {
                return false;
            }
        }

    }


    get VendorNbr() {
        return this.addForm.get('VendorNbr');
    }
    get Mes() {
        return this.addForm.get('Mes');
    }
    get ItemNbr() {
        return this.addForm.get('ItemNbr');
    }
    get DeptNbr() {
        return this.addForm.get('DeptNbr');
    }

    get RUT() {
        return this.addForm.get('RUT');
    }
    get StoreNumber() {
        return this.addForm.get('StoreNumber');
    }
    get Format() {
        return this.addForm.get('Format');
    }
    get Clase() {
        return this.addForm.get('Clase');
    }

    get VENDOR_NUMBER() {
        return this.addFormMx.get('VENDOR_NUMBER');
    }
    get AGREEMENT_NUMBER() {
        return this.addFormMx.get('AGREEMENT_NUMBER');
    }
    get UPC_NUMBER() {
        return this.addFormMx.get('UPC_NUMBER');
    }
    get LEGACY_ITEM_NUMBER() {
        return this.addFormMx.get('LEGACY_ITEM_NUMBER');
    }

    get ITEM_NO() {
        return this.addFormMx.get('ITEM_NO');
    }
    get DEPT_NO() {
        return this.addFormMx.get('DEPT_NO');
    }

    get SAP_NUMBER() {
        return this.addFormMx.get('SAP_NUMBER');
    }
    get STORE_NUM() {
        return this.addFormMx.get('STORE_NUM');
    }

    get PO_NUM() {
        return this.addFormMx.get('PO_NUM');
    }

    get CAT() {
        return this.addFormMx.get('CAT');
    }
    get PROC_DATE_START() {
        return this.addFormMx.get('PROC_DATE_START');
    }
    get PROC_DATE_END() {
        return this.addFormMx.get('PROC_DATE_END');
    }
    get CLUB_NBR() {
        return this.addFormMx.get('CLUB_NBR');
    }
    get BANNER_CODE() {
        return this.addFormMx.get('BANNER_CODE');
    }
    get FINELINE_NBR() {
        return this.addFormMx.get('FINELINE_NBR');
    }
    get PRICE_DATE_START() {
        return this.addFormMx.get('PRICE_DATE_START');
    }
    get PRICE_DATE_END() {
        return this.addFormMx.get('PRICE_DATE_END');
    }

    get PO_SOURCE() {
        return this.addFormMx.get('PO_SOURCE');
    }
    get FORMAT() {
        return this.addFormMx.get('FORMAT');
    }
    get SEQ_NO() {
        return this.addFormMx.get('SEQ_NO');
    }
    get MIN_DATE_START() {
        return this.addFormMx.get('MIN_DATE_START');
    }
    get MIN_DATE_END() {
        return this.addFormMx.get('MIN_DATE_END');
    }

    get INVOICE_NBR() {
        return this.addFormMx.get('INVOICE_NBR');
    }
    get ASSIGNMENT() {
        return this.addFormMx.get('ASSIGNMENT');
    }
    get RECV_DATE_START() {
        return this.addFormMx.get('RECV_DATE_START');
    }
    get RECV_DATE_END() {
        return this.addFormMx.get('RECV_DATE_END');
    }
    get INV_DATE_START() {
        return this.addForm.get('INV_DATE_START');
    }
    get INV_DATE_END() {
        return this.addForm.get('INV_DATE_END');
    }
    get RCVD_NBR() {
        return this.addForm.get('RCVD_NBR');
    }
    get INCLUDE() {
        return this.addForm.get('INCLUDE');
    }
    get REF_NUM () {
        return this.addForm.get('REF_NUM');
    }
    get PAID_AMOUNT_FROM () {
        return this.addForm.get('PAID_AMOUNT_FROM');
    }
    get FYR () {
        return this.addForm.get('FYR');
    }
    get SAP_VND () {
        return this.addForm.get('SAP_VND');
    }
    get DOC_NBR () {
        return this.addForm.get('DOC_NBR');
    }
    get SUPLR_NBR () {
        return this.addForm.get('SUPLR_NBR');
    }
    get INV_NUMBER () {
        return this.addForm.get('INV_NUMBER');
    }
    get ASSIGN () {
        return this.addForm.get('ASSIGN');
    }
    get PO_NUMBER () {
        return this.addForm.get('PO_NUMBER');
    }
    get UUID () {
        return this.addForm.get('UUID');
    }
    get CATEGORY () {
        return this.addForm.get('CATEGORY');    
    }
    get VENDOR_NBR() {
        return this.addFormMx.get('VENDOR_NBR');    
    }
   
    get GRE_DATE() {
        return this.addFormMx.get('GRE_DATE');    
    }
    get DEPARTMENT() {
        return this.addFormMx.get('DEPARTMENT');    
    }
    get ITEM_NUM() {
        return this.addFormMx.get('ITEM_NUM');    
    }
    get SAP_VENDOR() {
        return this.addFormMx.get('SAP_VENDOR');    
    }
    get EFFECTIVE_DATE() {
        return this.addFormMx.get('EFFECTIVE_DATE');    
    }
    get END_DATE() {
        return this.addFormMx.get('END_DATE');    
    }
    get EFFECTIVE_DATE_START() {
        return this.addFormMx.get('EFFECTIVE_DATE_START');    
    }
    get EFFECTIVE_DATE_END() {
        return this.addFormMx.get('EFFECTIVE_DATE_END');    
    }
    get END_DATE_START() {
        return this.addFormMx.get('END_DATE_START');    
    }
    get END_DATE_END() {
        return this.addFormMx.get('END_DATE_END');    
    }
    
    


    changeAuditYear(auditYears) {
        let quotedAndCommaSeparated = auditYears.map(d => `${d}`).join(',');
        console.log(quotedAndCommaSeparated)
        this.addFormMx.get('AUDIT_YEAR').setValue(quotedAndCommaSeparated);
    }

    getAllowTypesDropDown() {
        this.apiservice.getAllowTypes().subscribe((response) => {
            this.allowTypeDropDown = response;
        });
    }

    // tablenameConditions(tableName) {
    //     if (tableName == 'PPA_CL_COOPS_PURCHASE_ORDER_T' || tableName == 'PPA_CL_COOPS_POS_T' || tableName == 'PPA_CL_COOPS_COTS_T') {
    //         this.itemNDeptNumber = true;
    //     }
    // }

    tablenameConditions(tableName) {
        if (tableName == 'PPA_CL_COOPS_PURCHASE_ORDER_T' || tableName == 'PPA_CL_COOPS_POS_T'
            || tableName == 'PPA_CL_COOPS_COTS_T') {
            this.itemNumber = true;
            this.deptNumber = true
        }
        if (tableName == 'PPA_CL_COOPS_NEW_STORE_DEDUCTION_T') {
            this.deptNumber = true
            this.storeNbr = true
        }

    }

    generatingOldData(data) {
        this.addForm.get('AuditYear').setValue(data.auditYear);
    }

    formatDropDownValue(CLCode) {
        if (this.tableName == 'PPA_CL_COOPS_POS_T' || this.tableName == 'PPA_CL_COOPS_PURCHASE_ORDER_T' ||
            this.tableName == 'PPA_CL_COOPS_COTS_T') {
            this.showLoader = true;
            this.apiservice.getClaimsDropdowns(CLCode, this.pageID).subscribe((response) => {
                this.showLoader = false;
                this.AllDropdowns = response;
                for (var i = 0; i < this.AllDropdowns.length; i++) {
                    if (this.AllDropdowns[i].Lookup_Code == 'FORMAT_TYPE') {
                        this.FormatList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                    }
                    if (this.AllDropdowns[i].Lookup_Code == 'PRIVATE_LBL') {
                        this.privateLabelList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                    }
                }
            });
        }
    }

    onSubmit() {
        this.showLoader = true;
        if (this.countryCode === 'CL') {
            if (this.addForm.value.PurchaseDateEnd < this.addForm.value.PurchaseDateStart) {
                alert('Please Select End Date Greater Than Start Date');
                this.showLoader = false;
                return false;
            }
            if (this.addForm.value.SalesDateEnd < this.addForm.value.SalesDateStart) {
                alert('Please Select End Date Greater Than Start Date');
                this.showLoader = false;
                return false;
            }
            if (this.addForm.value.PeriodEnd < this.addForm.value.PeriodStart) {
                alert('Please Select End Date Greater Than Start Date');
                this.showLoader = false;
                return false;
            }

            if (this.addForm.value.PurchaseDateEnd == null) {
                this.addForm.value.PurchaseDateEnd = this.addForm.value.PurchaseDateStart;
            }
            if (this.addForm.value.SalesDateEnd == null) {
                this.addForm.value.SalesDateEnd = this.addForm.value.SalesDateStart;
            }
            if (this.addForm.value.PeriodEnd == null) {
                this.addForm.value.PeriodEnd = this.addForm.value.PeriodStart;
            }
            this.apiservice.dataPageFilters(this.addForm.value, this.tableName, 0, this.keyName, this.keyOrder).subscribe((response) => {
                this.filteredList = response;
                this.showLoader = false;
                // this.openSnackBar("Assigned Auditor successfully", "Ok")
                this.dialogRef.close({
                    data: this.filteredList,
                    payload: this.addForm.value,
                    tableName: this.tableName,
                    sort: 0,
                    keyWord: this.keyName,
                    keyOrder: this.keyOrder
                });
                //  this.router.navigate(['data/' + this.filtersData.countryCode + "/" + this.tableName]);
                // this.Refresh();
            });
        } else {
            if (this.addFormMx.value.SALES_DATE_END < this.addFormMx.value.SALES_DATE_START) {
                alert('Please Select End Date Greater Than Start Date');
                this.showLoader = false;
                return false;
            }
            if (this.addFormMx.value.GRE_DATE_END < this.addFormMx.value.GRE_DATE_START) {
                alert('Please Select End Date Greater Than Start Date');
                this.showLoader = false;
                return false;
            }

            const val = this.addFormMx.value;
            // for (var key in v) {
            //   if (v[key] === null) {
            //     v[key] = 'null'
            //   }
            // }
            let fileYEarKeyword;

            if (this.tableName == 'PPA_SCAN_REBATE_DEDUCTIONS') {
                fileYEarKeyword = 'YEAR_MONTH';
            } else if (this.tableName == 'Item_Master_Sams' || this.tableName == 'Item_Master_WM') {
                fileYEarKeyword = 'FILEYEAR';
            } else {
                fileYEarKeyword = 'AUDIT_YEAR';
            }

            this.apiservice.dataPageFiltersMX(val, this.tableName, 0, fileYEarKeyword, this.keyOrder).subscribe(
                (response) => {
                    this.filteredList = response;
                    this.showLoader = false;
                    // this.openSnackBar("Assigned Auditor successfully", "Ok")
                    this.dialogRef.close({
                        data: this.filteredList,
                        payload: this.addFormMx.value,
                        tableName: this.tableName,
                        sort: 0,
                        keyWord: fileYEarKeyword,
                        keyOrder: this.keyOrder
                    });
                    //  this.router.navigate(['data/' + this.filtersData.countryCode + "/" + this.tableName]);
                    // this.Refresh();
                },
                (error) => {
                    this.filteredList = [];
                    this.showLoader = false;
                    // this.openSnackBar("Assigned Auditor successfully", "Ok")
                    this.dialogRef.close({
                        data: this.filteredList,
                        payload: this.addFormMx.value
                    });
                }
            );
        }
    }


}
