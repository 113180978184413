import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'PPAWalmart';

    constructor(private router: Router) {}

    ngOnInit() {
        console.log("Framebusting script started in app.component.ts");
        if (window.top !== window.self) {
          console.log("Access Denied: Loaded in an iframe");
          
          document.body.innerHTML = "<h1>Access Denied</h1><p>This application cannot be loaded in an iframe for security reasons.</p>";
          document.body.style.textAlign = "center";
          document.body.style.marginTop = "20%";
          window.stop();
        } else {
          console.log("Application loaded directly, not in an iframe");
        }
    }
}
