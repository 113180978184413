// import { Inject, Injectable } from '@angular/core';
// import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { OKTA_AUTH } from '@okta/okta-angular';
// import { OktaAuth } from '@okta/okta-auth-js';

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//     constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

//     intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
//         return next.handle(this.addAuthHeaderToAllowedOrigins(request));
//     }

//     private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
//         let req = request;

//         const allowedOrigins = ['https://ppatool-dev.genpact.com/'];
//         //  const allowedOrigins = ['https://localhost:4200/'];
//         if (!!allowedOrigins.find((origin) => request.url.includes(origin))) {
//             debugger
//             const authToken = this._oktaAuth.getAccessToken();
//             localStorage.setItem('accesstoken', authToken);
//             req = request.clone({
//                 setHeaders: { Authorization: `Bearer ${authToken}` }
//             });
//         }

//         return req;
//     }
// }

import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        

        return from(this.addAuthHeaderToAllowedOrigins(request)).pipe(
            switchMap(req => {
                console.log('Request after adding auth header:', req); 
                return next.handle(req);
            })
        );
    }

    private async addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): Promise<HttpRequest<unknown>> {
        let req = request;

       
        const allowedOrigins = ['https://ppatool-dev-api.genpact.com/']; 
        const isAllowedOrigin = allowedOrigins.some(origin => request.url.startsWith(origin));

        

        if (isAllowedOrigin) {
            console.log('Request URL matches allowed origin, processing token.');

            try {
               
                const isAuthenticated = await this._oktaAuth.isAuthenticated();
                if (!isAuthenticated) {
                    console.warn('User is not authenticated. No token will be attached.');
                    return req;  
                }

                
                const authToken = await this._oktaAuth.getAccessToken();
                console.log('Auth Token retrieved:', authToken);  

                if (authToken) {
                    
                    req = request.clone({
                        setHeaders: { Authorization: `Bearer ${authToken}` }
                    });
                    
                } else {
                    console.warn('No auth token found.');
                }
            } catch (error) {
                console.error('Error retrieving token:', error);  
            }
        } else {
            console.warn('Request URL does not match allowed origin. No token will be attached.');
        }

        return req;  
    }
}

