import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesRoutingModule } from './pages-routing.module';

import { FooterComponent } from '../layout/footer/footer.component';
import { HeaderComponent } from '../layout/header/header.component';
import { CreateClaimComponent } from './create-claim/create-claim.component';
import { AssignComponent } from './assign/assign.component';
import { AssignHistoryComponent } from './assign-history/assign-history.component';
import { AgGridModule } from 'ag-grid-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// used to create fake backend
import { fakeBackendProvider } from '../_helpers';

import { JwtInterceptor, ErrorInterceptor } from '../_helpers';
import { BrowserModule } from '@angular/platform-browser';
// import { HomeComponent } from './home';
// import { AdminComponent } from './admin';
// import { LoginComponent } from './login';

//Material Design
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ChartModule } from 'angular-highcharts';

import { CheckboxSelectionCallbackParams, ColDef, GridReadyEvent, HeaderCheckboxSelectionCallbackParams } from 'ag-grid-community';
import { LoginComponent } from './login/login.component';
import { ManagePRComponent } from './manage-pr/manage-pr.component';
import { EditDataComponent } from './popup/edit-data/edit-data.component';
import { DuplicateDataComponent } from './popup/duplicate-data/duplicate-data.component';
import { ChangeStatusComponent } from './popup/change-status/change-status.component';
import { AssignAuditorComponent } from './popup/assign-auditor/assign-auditor.component';
import { AddRowComponent } from './popup/add-row/add-row.component';
import { CreatePackageComponent } from './popup/create-package/create-package.component';
import { CreateVolumePackageComponent } from './popup/create-volume-package/create-volume-package.component';
import { CreateClaimPackageComponent } from './popup/create-claim-package/create-claim-package.component';
import { CreatePaybackComponent } from './popup/create-payback/create-payback.component';
import { PdfViewComponent } from './popup/pdf-view/pdf-view.component';
import { DataComponent } from './data/data.component';
import { ReportsComponent } from './reports/reports.component';
import { GridHeaderSelectComponent } from './grid-header-select.component';
import { BulkUPloadComponent } from './popup/bulk-upload/bulk-upload.component';
import { BreadcrumsComponent } from '../layout/breadcrums/breadcrums.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { TableModalComponent } from './table-modal/table-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MiscellaneousPRComponent } from './popup/miscellaneous-pr/miscellaneous-pr.component';
import { DataFiltersComponent } from './popup/data-filters/data-filters.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FilterBulkUploadComponent } from './popup/filter-bulk-upload/filter-bulk-upload.component';
import { AddRowDataComponent } from './popup/add-row-data/add-row-data.component';
import { EditRowDataComponent } from './popup/edit-row-data/edit-row-data.component';
import { CommaFilterComponent } from './popup/comma-filter/comma-filter.component';
import { PivotTableComponent } from './popup/pivot-table/pivot-table.component';
import { ChartComponent } from './popup/charts/chart.component';

@NgModule({
    declarations: [
        DashboardComponent,
        CreateClaimComponent,
        AssignComponent,
        AssignHistoryComponent,
        FooterComponent,
        HeaderComponent,
        LoginComponent,
        ManagePRComponent,
        EditDataComponent,
        DuplicateDataComponent,
        ChangeStatusComponent,
        AssignAuditorComponent,
        AddRowComponent,
        CreatePackageComponent,
        CreateVolumePackageComponent,
        CreateClaimPackageComponent,
        CreatePaybackComponent,
        PdfViewComponent,
        DataComponent,
        ReportsComponent,
        GridHeaderSelectComponent,
        BulkUPloadComponent,
        BreadcrumsComponent,
        TableModalComponent,
        ProfileComponent,
        MiscellaneousPRComponent,
        DataFiltersComponent,
        FilterBulkUploadComponent,
        AddRowDataComponent,
        EditRowDataComponent,
        CommaFilterComponent,
        PivotTableComponent,
        ChartComponent
    ],

    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        PagesRoutingModule,

        AgGridModule.withComponents([]),
        // Ng4LoadingSpinnerModule,
        //mat
        MatRadioModule,
        MatButtonModule,
        MatIconModule,
        MatRippleModule,
        MatNativeDateModule,
        MatMenuModule,
        MatCardModule,
        MatSelectModule,
        MatBottomSheetModule,
        MatDialogModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatTableModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        DragDropModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        NzTransferModule,
        NzTableModule,
        NzDropDownModule,
        ChartModule
    ],
    exports: [DragDropModule],
    // providers: [DatePipe],
    providers: [
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider
    ],
    
})
export class PagesModule {}
